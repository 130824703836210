import { Message } from '..'
import useLicensesHook from '../../api/licenses'
import useTypeNetworksHook from '../../api/type-networks'
import useTypeLicensesHook from '../../api/type-licenses'
import React, { useState, useEffect } from "react"
import { AiOutlineFileDone, AiOutlineRadarChart } from "react-icons/ai"
import useBranchLicensesHook from '../../api/branch-licenses'
import { FaPenAlt, FaTrash, FaCheckCircle, FaTimesCircle, FaBroom } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'

const FormBranchLicenses = ({
  id,
  branchLicense,
  edit,
  isError,
  error,
}) => {

  const { getLicenses } = useLicensesHook({})
  const { getTypeNetworks } = useTypeNetworksHook({})
  const { getTypeLicenses } = useTypeLicensesHook({})
  const { updateBranchLicense } = useBranchLicensesHook({ page: 1, q: '' })
  const { mutateAsync: mutateAsyncUpdateBranchLicense, error: errorValidations } = updateBranchLicense
  const { data } = getLicenses
  const { data: dataTypeLicenses } = getTypeLicenses
  const { data: dataTypeNetworks } = getTypeNetworks

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const branchId = searchParams.get('branch_id')


  // Inicio - BranchLicense y typeNetwork
  const [license, setLicense] = useState('')
  const [validSince, setValidSince] = useState('')
  const [validUntil, setValidUntil] = useState('')
  const [typeLicense, setTypeLicense] = useState('')
  const [typeNetworkMember, setTypeNetworkMember] = useState('')

  const [maxNumberMembersXBranches, setMaxNumberMembersXBranches] = useState(0)
  const [active, setActive] = useState(false)
  const [withNotification, setWithNotification] = useState(false)
  const [showValidity, setShowValidity] = useState(false)
  const [showTypeNetworkUser, setShowTypeNetworkUser] = useState(false)

  const [editTypeNetwork, setEditTypeNetwork] = useState(false)
  const [typeNetworkName, setTypeNetworkName] = useState('')
  const [typeNetwork, setTypeNetwork] = useState('')
  const [typeNetworkId, setTypeNetworkId] = useState('')
  const [maxNumberUsersXNetwork, setMaxNumberUsersXNetwork] = useState(0)
  const [activeTypeNetwork, setActiveTypeNetwork] = useState(false)
  var [typeNetworks, setTypeNetworks] = useState([])
  const [errorTypeNetwork, setErrorTypeNetwork] = useState('')
  const [errorTypeNetworkGeneral, setErrorTypeNetworkGeneral] = useState('')


  var typeNetworkList = []


  useEffect(() => {

    if (branchLicense != null) {

      setTypeLicense(branchLicense.typeLicense)
      const typeLicenseFound = dataTypeLicenses.data.filter(typeLicenseObject => typeLicenseObject._id === branchLicense.typeLicense)

      if (typeLicenseFound.length > 0) {
        if (typeLicenseFound[0].mnemonic == 'LCRF') {
          setShowValidity(true)
        } else {
          setShowValidity(false)
        }

      } else {
        setShowValidity(false)
      }

      setTypeNetworkMember(branchLicense.typeNetworkMember)
      const typeNetworkMemberFound = dataTypeNetworks.data.filter(typeNetworkMemberObject => typeNetworkMemberObject._id === branchLicense.typeNetworkMember)

      if (typeNetworkMemberFound.length > 0) {
        if (typeNetworkMemberFound[0].mnemonic != 'APNM') {
          setShowTypeNetworkUser(true)
        } else {
          setShowTypeNetworkUser(false)
        }

      } else {
        setShowTypeNetworkUser(false)
      }

      setValidSince(branchLicense.validSince != undefined ? branchLicense.validSince.substring(0, 10) : '')
      setValidUntil(branchLicense.validUntil != undefined ? branchLicense.validUntil.substring(0, 10) : '')
      setMaxNumberMembersXBranches(branchLicense.maxNumberMembersXBranches)
      if (branchLicense.active) {
        document.getElementById("active").checked = true;
      }
      setActive(branchLicense.active)

      if (branchLicense.withNotification) {
        document.getElementById("withNotification").checked = true;
      }
      setWithNotification(branchLicense.withNotification)

      var typeNetworkListTemporal = []
      if (branchLicense.typeNetworks != undefined) {
        for (let i = 0; i < branchLicense.typeNetworks.length; i++) {
          typeNetworkListTemporal.push({
            id: branchLicense.typeNetworks[i]._id,
            name: branchLicense.typeNetworks[i].name,
            type: branchLicense.typeNetworks[i].type,
            maxNumberUsersXNetwork: branchLicense.typeNetworks[i].maxNumberUsersXNetwork,
            active: branchLicense.typeNetworks[i].active,
          })
        }
      }
      setTypeNetworks(typeNetworkListTemporal)
      setLicense(branchLicense.license._id)
      document.getElementById('license').value = branchLicense.license.name

    } else {
      cleanBranchLicense()
    }
  }, [branchLicense])


  const cleanBranchLicense = () => {
    setLicense('')
    setValidSince('')
    setValidUntil('')
    setTypeLicense('')
    setTypeNetworkMember('')
    setMaxNumberMembersXBranches(0)


    if (document.getElementById("active") != null) {
      document.getElementById("active").checked = false
    }

    setActive(false)
    setWithNotification(false)
    setEditTypeNetwork(false)
    setShowValidity(false)
    setShowTypeNetworkUser(false)
    setTypeNetworkName('')
    setTypeNetwork('')
    setTypeNetworkId('')

    setMaxNumberUsersXNetwork(0)
    if (document.getElementById("activeTypeNetwork") != null) {
      document.getElementById("activeTypeNetwork").checked = false
    }
    setActiveTypeNetwork(false)
    setTypeNetworks([])
    setErrorTypeNetwork('')
    setErrorTypeNetworkGeneral('')
    typeNetworkList = []
  }

  // Metodo mostrar por tipo de licencia 
  const showValidityByTypeLicense = async (typeLicense) => {

    const typeLicenseFound = dataTypeLicenses.data.filter(typeLicenseObject => typeLicenseObject._id === typeLicense)

    setTypeLicense(typeLicense)

    if (typeLicenseFound.length > 0) {
      if (typeLicenseFound[0].mnemonic == 'LCRF') {
        setShowValidity(true)
      } else {
        setShowValidity(false)
      }

    } else {
      setShowValidity(false)
    }
  }


  // Metodo mostrar por tipo de red 
  const showTypeNetworkUserByTypenetwork = async (typeNetwork) => {

    const typeNetworkUserFound = dataTypeNetworks.data.filter(typeNetworkUserObject => typeNetworkUserObject._id === typeNetwork)

    setTypeNetworkMember(typeNetwork)

    if (typeNetworkUserFound.length > 0) {
      if (typeNetworkUserFound[0].mnemonic != 'APNM') {
        setShowTypeNetworkUser(true)
      } else {
        setShowTypeNetworkUser(false)
      }

    } else {
      setShowTypeNetworkUser(false)
    }
  }

  const addBranchLicense = async () => {

    // Validacion: Debe ingresar al menos un tipo de red
    if (typeNetworks.length == 0 && showTypeNetworkUser) {
      setErrorTypeNetwork('Debe ingresar al menos un Tipo de Red')
      return false
    }

    await mutateAsyncUpdateBranchLicense({
      _id: branchId,
      branchId: branchId,
      licenseId: license,
      validSince: validSince,
      validUntil: validUntil,
      typeLicense: typeLicense,
      withNotification: withNotification,
      typeNetworkMember: typeNetworkMember,
      maxNumberMembersXBranches: maxNumberMembersXBranches,
      active: active,
      typeNetworkList: typeNetworks
    })
    // Seteamos las variables  
    cleanBranchLicense()
    document.getElementById("close-branch-license-modal").click()
  }

  const editBranchLicense = async () => {

    // Validacion: Debe ingresar al menos un tipo de red
    if (typeNetworks.length == 0 && showTypeNetworkUser) {
      setErrorTypeNetwork('Debe ingresar al menos un Tipo de Red')
      return false
    }


    await mutateAsyncUpdateBranchLicense({
      _id: id != null ? id : branchId,
      branchId: branchId,
      licenseId: license,
      validSince: validSince,
      validUntil: validUntil,
      typeLicense: typeLicense,
      withNotification: withNotification,
      typeNetworkMember: typeNetworkMember,
      maxNumberMembersXBranches: maxNumberMembersXBranches,
      active: active,
      typeNetworkList: typeNetworks,
    })

    // Seteamos las variables  
    cleanBranchLicense()
    document.getElementById("close-branch-license-modal").click()
  }


  // Agregar el tipo de red
  const addTypeNetworkBranchLicense = async () => {

    // Validacion de que no repitan el tipo de red

    var typeNetworkFound = typeNetworks.find((e) => e.name == typeNetworkName)


    if (typeNetworkFound != undefined) {
      setErrorTypeNetworkGeneral('Nombre de Tipo de Red ya existe. Ingrese otro')
      return false
    } else {
      typeNetworkList = typeNetworks
      typeNetworkList.push({
        id: typeNetworkId,
        name: typeNetworkName,
        maxNumberUsersXNetwork: maxNumberUsersXNetwork,
        type: typeNetwork,
        active: activeTypeNetwork
      })
      setTypeNetworks(typeNetworkList)

      await mutateAsyncUpdateBranchLicense({
        _id: id != null ? id : branchId,
        branchId: branchId,
        licenseId: license,
        validSince: validSince,
        validUntil: validUntil,
        typeLicense: typeLicense,
        withNotification: withNotification,
        typeNetworkMember: typeNetworkMember,
        maxNumberMembersXBranches: maxNumberMembersXBranches,
        active: active,
        typeNetworkList: typeNetworkList
      })

    }
    cleanTypeNetworkBranchLicense()

    document.getElementById("close-type-network").click()
  }


  // Mostrar el nombre del tipo de red por medio del Id
  function showNameTypeNetwork(typeNetworkId) {
    if (typeNetworkId != undefined) {
      var typeNetworkFound = dataTypeNetworks.data.find((e) => e._id == typeNetworkId)
      return typeNetworkFound.name
    }
  }

  // Metodo mostrar valores para  editar un tipo de red
  const showEditTypeNetworkFunction = (typeNetwork) => {

    setEditTypeNetwork(true)
    setTypeNetworkId(typeNetwork._id)
    setTypeNetworkName(typeNetwork.name)
    setTypeNetwork(typeNetwork.type)
    setMaxNumberUsersXNetwork(typeNetwork.maxNumberUsersXNetwork)
    setActiveTypeNetwork(typeNetwork.active)
    if (typeNetwork.active)
      document.getElementById("activeTypeNetwork").checked = true
    else
      document.getElementById("activeTypeNetwork").checked = false

  }

  // Metodo para  editar un tipo de red
  const editTypeNetworkFunction = async () => {

    setEditTypeNetwork(true)
    setTypeNetworkName(typeNetworkName)
    setTypeNetwork(typeNetwork)
    setMaxNumberUsersXNetwork(maxNumberUsersXNetwork)
    setActiveTypeNetwork(activeTypeNetwork)

    typeNetworks.map(function (typeNetworkObject) {
      if (typeNetworkObject.name == typeNetworkName) {
        typeNetworkObject.maxNumberUsersXNetwork = maxNumberUsersXNetwork
        typeNetworkObject.type = typeNetwork
        typeNetworkObject.id = typeNetworkId
        typeNetworkObject.active = activeTypeNetwork
      }
    })

    setTypeNetworks(typeNetworks)
    await mutateAsyncUpdateBranchLicense({
      _id: id != null ? id : branchId,
      branchId: branchId,
      licenseId: license,
      validSince: validSince,
      validUntil: validUntil,
      typeLicense: typeLicense,
      withNotification: withNotification,
      typeNetworkMember: typeNetworkMember,
      maxNumberMembersXBranches: maxNumberMembersXBranches,
      active: active,
      typeNetworkList: typeNetworks
    })

    cleanTypeNetworkBranchLicense()
    document.getElementById("close-type-network").click()
  }

  // Metodo para eliminar un tipo de red
  const deleteTypeNetwork = async (typeNetworkName) => {

    const listTypeNetworks = typeNetworks.filter(typeNetwork => typeNetwork.name !== typeNetworkName)

    setTypeNetworks(listTypeNetworks)

    await mutateAsyncUpdateBranchLicense({
      _id: id != null ? id : branchId,
      branchId: branchId,
      licenseId: license,
      validSince: validSince,
      validUntil: validUntil,
      typeLicense: typeLicense,
      withNotification: withNotification,
      typeNetworkMember: typeNetworkMember,
      maxNumberMembersXBranches: maxNumberMembersXBranches,
      active: active,
      typeNetworkList: listTypeNetworks
    })
  }

  // Limpiar el tipo de red
  const cleanTypeNetworkBranchLicense = () => {
    setEditTypeNetwork(false)
    setTypeNetworkName('')
    setTypeNetwork('')
    setTypeNetworkId('')
    setMaxNumberUsersXNetwork(0)
    setActiveTypeNetwork(false)
    if (document.getElementById("activeTypeNetwork") != null) {
      document.getElementById("activeTypeNetwork").checked = false
    }
    setErrorTypeNetwork('')
    setErrorTypeNetworkGeneral('')
  }

  // Fin - BranchLicense y typeNetwork

  return (
    <div
      className='modal fade'
      id='branchLicenseModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='branchLicenseModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-pequeno'>
        {errorValidations && <Message variant='danger'>{errorValidations}</Message>}
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='branchLicenseModalLabel'>
              {edit ? 'Editar Contrato por Sucursal' : 'Agregar Contrato a la Sucursal'}
            </h3>
            {edit == false ? (
              <button
                type='button'
                id='close-branch-license-modal'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={cleanBranchLicense}
              ></button>) : (<button
                type='button'
                id='close-branch-license-modal'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'></button>)
            }
          </div>
          <div className='modal-body'>
            {isError && <Message variant='danger'>{error}</Message>}
            <form >

              <div className='row'>
                <div className='col-md-6'>
                  <label htmlFor="license"><b>Licencia:</b></label>
                  <select onChange={(event) => setLicense(event.target.value)}
                    id='license' name='license' className='form-control' value={license}>
                    <option key="" >Seleccione una opción</option>
                    {data && data.data.map((license) => (
                      <option key={license._id} value={license._id} >{license.name}</option>
                    ))}
                  </select>
                </div>
                <div className='col-md-6'>
                  <label htmlFor="typeLicense"><b>Tipo de Licencia:</b></label>
                  <select onChange={(event) => showValidityByTypeLicense(event.target.value)}

                    id='typeLicense' name='typeLicense' className='form-control' value={typeLicense}>
                    <option key="" value="" >Seleccione una opción</option>
                    {dataTypeLicenses && dataTypeLicenses.data.map((licenseType) => (
                      <option key={licenseType._id} value={licenseType._id} >{licenseType.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              {showValidity ? (
                <div className='row'>
                  <div className='col-md-6'>
                    <label htmlFor="validSince"><b>Vigencia Desde:</b></label>
                    <input
                      type='date'
                      name='validSince'
                      value={validSince}
                      onChange={(event) => setValidSince(event.target.value)}
                      className='form-control'
                    />
                  </div>
                  <div className='col-md-6'>
                    <label htmlFor="validSince"><b>Vigencia Hasta:</b></label>
                    <input
                      type='date'
                      name='validUntil'
                      value={validUntil}
                      onChange={(event) => setValidUntil(event.target.value)}
                      className='form-control'
                    />
                  </div>
                </div>) : ''}

              <div className='row'>
                <div className='col-md-6'>
                  <label htmlFor="typeNetwork"><b>Tipo de Red Abonado Principal:</b></label>
                  <select onChange={(event) => showTypeNetworkUserByTypenetwork(event.target.value)}
                    id='typeNetworkMember' name='typeNetworkMember' className='form-control' value={typeNetworkMember}>
                    <option value="" >Seleccione una opción</option>
                    {dataTypeNetworks && dataTypeNetworks.data.map((typeNetwork) => (
                      typeNetwork.origin == 'member_branch' ?
                        <option key={typeNetwork._id} value={typeNetwork._id} >{typeNetwork.name}</option> : ''

                    ))}
                  </select>
                </div>
                <div className='col-md-6'>
                  <label htmlFor="maxNumberMembersXBranches"><b>Nª Máximo Abonados Principales X Sucursal:</b></label>
                  <input
                    type='number'
                    name='maxNumberMembersXBranches'
                    value={maxNumberMembersXBranches}
                    onChange={(event) => setMaxNumberMembersXBranches(event.target.value)}
                    className='form-control'
                  />
                </div>
              </div>
              <div className='row'>
                <div className="col-md-6">
                  <label htmlFor="withNotification"><b>Con Notificación: (Complementaria)</b></label>
                  <div className='form-check form-switch form-control'>
                    <input className='form-check-input'
                      type='checkbox'
                      id='withNotification'
                      name='withNotification'
                      value={withNotification}
                      onChange={(event) => setWithNotification(event.target.checked)}
                    />
                    <label className='form-check-label' htmlFor='withNotification'>
                      Si
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="active"><b>Estado:</b></label>
                  <div className='form-check form-switch form-control'>
                    <input className='form-check-input'
                      type='checkbox'
                      id='active'
                      name='active'
                      value={active}
                      onChange={(event) => setActive(event.target.checked)}
                    />
                    <label className='form-check-label' htmlFor='active'>
                      Activo
                    </label>
                  </div>
                </div>
              </div>
              <br></br>
              {showTypeNetworkUser ? (
                <div>
                  <center>
                    <a className="btn btn-success" href="#open-modal-type-network" data-bs-toggle="tooltip" data-bs-placement="top" title="Clic para Agregar Tipo de Red"> <AiOutlineRadarChart />  &nbsp;Agregar Tipo de Red Abonado Secundario</a>
                  </center>


                  <center>{errorTypeNetwork ? (<span className='text-danger'>{errorTypeNetwork}</span>) : ''} </center>
                  <br></br>
                  <table className='table * table-sm table-border'>
                    <thead className='border-0'>
                      <tr className='text-center'>
                        <th>Nombre Red</th>
                        <th># Usuarios Red</th>
                        <th>Tipo de Red</th>
                        <th>Activo</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>

                      {typeNetworks && typeNetworks.map((listValue, index) => {
                        return (
                          <tr key={index} className='text-center'>
                            <td>{listValue.name}</td>
                            <td>{listValue.maxNumberUsersXNetwork}</td>
                            <td>{showNameTypeNetwork(listValue.type)}</td>
                            <td>
                              {listValue.active ? (
                                <FaCheckCircle className='text-success' />
                              ) : (
                                <FaTimesCircle className='text-danger' />
                              )}
                            </td>
                            <td>
                              <div className='btn-group'>
                                <a className="btn btn-primary btn-sm rounded-pill" href="#open-modal-type-network" onClick={() => showEditTypeNetworkFunction(listValue)}
                                  data-bs-toggle="tooltip" data-bs-placement="top" title="Clic para Editar el tipo de Red" > <FaPenAlt /></a>
                                &nbsp;
                                <button className='btn btn-danger btn-sm ms-1 rounded-pill' onClick={(event) => { (window.confirm('Desea eliminar el tipo de Red ?')) ? deleteTypeNetwork(listValue.name) : (event.preventDefault()) }}
                                  data-bs-toggle="tooltip" data-bs-placement="top" title="Clic para Eliminar un el tipo de Red">
                                  <span><FaTrash /></span>
                                </button>
                              </div>
                            </td>
                          </tr>)
                      })}
                    </tbody>
                  </table>

                  <div id="open-modal-type-network" className="modal-window-subinternal" style={{ marginTop: '200px' }}>
                    <div>
                      <b><h4>
                        {editTypeNetwork ? 'Editar Tipo de Red Abonado Secundario' : 'Agregar Tipo de Red Abonado Secundario'}
                      </h4></b>
                      <a href="#" id="close-type-network" title="Close" className="modal-close" onClick={() => cleanTypeNetworkBranchLicense()} >&#x2715;</a>
                      <hr></hr>
                      <div className='row'>
                        <div className="col-md-12">
                        </div>
                      </div>
                      <center>{errorTypeNetworkGeneral ? (<span className='text-danger'>{errorTypeNetworkGeneral}</span>) : ''} </center>
                      &nbsp;
                      <div className='row'>

                        <div className="col-md-6">
                          <label htmlFor="typeNetworkName"><b>Nombre de Red:</b></label>
                          <input
                            type='text'
                            name='typeNetworkName'
                            value={typeNetworkName}
                            onChange={(event) => setTypeNetworkName(event.target.value)}
                            className='form-control'
                            disabled={editTypeNetwork ? true : false}
                          />
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor="maxNumberUsersXNetwork"><b># Máximo Usuarios Por Red:</b></label>
                          <input
                            type='number'
                            name='maxNumberUsersXNetwork'
                            value={maxNumberUsersXNetwork}
                            onChange={(event) => setMaxNumberUsersXNetwork(event.target.value)}
                            className='form-control'
                          />
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor="typeNetwork"><b>Tipo de Red Abonado Secundario:</b></label>
                          <select onChange={(event) => setTypeNetwork(event.target.value)}
                            id='typeNetwork' name='typeNetwork' className='form-control' value={typeNetwork}>
                            <option value="" >Seleccione una opción</option>
                            {dataTypeNetworks && dataTypeNetworks.data.map((typeNetwork) => (
                              typeNetwork.origin == 'user_member' ?
                                <option key={typeNetwork._id} value={typeNetwork._id} >{typeNetwork.name}</option> : ''


                            ))}
                          </select>
                        </div>
                        <div className='col-md-6'>
                          <label htmlFor="activeTypeNetwork"><b>Estado:</b></label>
                          <div className='form-check form-switch form-control'>
                            <input className='form-check-input'
                              type='checkbox'
                              id='activeTypeNetwork'
                              name='activeTypeNetwork'
                              value={activeTypeNetwork}
                              onChange={(event) => setActiveTypeNetwork(event.target.checked)}
                            />
                            <label className='form-check-label' htmlFor='activeTypeNetwork'>
                              Activo
                            </label>
                          </div>
                        </div>

                        <div className='row'>&nbsp;</div>
                        <div className='row'>
                          <div className="col-md-6">
                            <button type='button' className='btn btn-primary form-control' onClick={() => editTypeNetwork ? editTypeNetworkFunction() : addTypeNetworkBranchLicense()}  >
                              <AiOutlineRadarChart /> &nbsp; {editTypeNetwork ? 'Actualizar' : 'Crear '}
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button type='button' className='btn btn-primary form-control' onClick={() => cleanTypeNetworkBranchLicense()}  >
                              <FaBroom /> &nbsp; Limpiar
                            </button>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>) : ''}


              <div className='row'>&nbsp;</div>
              <div className='row'>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <button type='button' onClick={edit ? editBranchLicense : addBranchLicense} className='btn btn-primary form-control' data-bs-toggle="tooltip" data-bs-placement="top" title={edit ? 'Actualizar Contrato' : 'Agregar Contrato'}>
                    <AiOutlineFileDone /> &nbsp; {edit ? 'Actualizar' : 'Agregar '}
                  </button>
                </div>
                <div className="col-md-4"></div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormBranchLicenses
