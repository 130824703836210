import { Message } from '..'
import { AiOutlineSync, AiOutlinePushpin } from "react-icons/ai"
import React, { Component } from "react"
import useBranchesHook from '../../api/branches'
import GoogleMap from 'google-map-react'

// Inicio - Mapa

let circle
let marker
var defaultRadius = 10000
var defaultLatitude = 0
var defaultLongitude = 0
let zoom = 10
let updateSuccess = ''

class GoogleMaps extends Component {

  constructor(props) {
    super(props)
    this.state = { latitude: defaultLatitude, longitude: defaultLongitude, inputRadius: defaultRadius }
  }

  addCircleRadius = (radiusValue) => {
  
    var radius = radiusValue
    this.setState({
      inputRadius: Number(radius)
    })
    circle.setRadius(Number(radius))
    this.forceUpdate()
    defaultRadius = radius
  }

  resetCircleRadius = () => {


    if (navigator.geolocation) {
      // Obtenemos la actual localizacion de los usuarios
      navigator.geolocation.getCurrentPosition(
        (position) => {

          // Obtenemos las coordenadas en dos variables
          defaultLatitude = position.coords.latitude
          defaultLongitude = position.coords.longitude
          defaultRadius = 10000



          this.setState({
            latitude: defaultLatitude, longitude: defaultLongitude, inputRadius: defaultRadius
          })

          this.forceUpdate()
          circle.setRadius(Number(defaultRadius))

          circle.setCenter({ lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude) })

          document.getElementById('inputRadius').value = defaultRadius
          marker.setPosition({ lat: Number(defaultLatitude), lng: Number(defaultLongitude) })

          marker.bindTo("position", circle, "center")

        },
        // Si hay un error al conseguir la localizacion del usuario
        (error) => {
          console.error('Error getting user location:', error);
        }
      )
    }
    // Si la localización no es soportada por el navegador del usuario
    else {
      console.error('Geolocation no es soportado por este navegador.');
    }
  }

  addLocationBranch = async () => {

    var { mutateAsync: mutateAsyncUpdate } = this.props.updateBranch

    updateSuccess = ''

    const updateLocation = await mutateAsyncUpdate({
      _id: this.props.branch_id,
      location: this.state,
      type: 'update_branch_location'
    })

    updateSuccess = updateLocation.message

  }

  render() {


    var apiIsLoaded = (map, maps) => {

      defaultLatitude = this.props.latitudeBranch
      defaultLongitude = this.props.longitudeBranch
      defaultRadius = this.props.radiusBranch

      this.setState({
        latitude: defaultLatitude, longitude: defaultLongitude, inputRadius: defaultRadius
      })
      this.forceUpdate()

      circle = new maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.3,
        map,
        center: { lat: Number(defaultLatitude), lng: Number(defaultLongitude) },
        radius: Number(defaultRadius),
        draggable: true
      })

      marker = new maps.Marker({
        position: { lat: Number(defaultLatitude), lng: Number(defaultLongitude) },
        map,
        draggable: true
      })

      document.getElementById('inputRadius').value = defaultRadius

      marker.bindTo("position", circle, "center")

      maps.event.addListener(marker, 'dragend',
        function (marker) {
          var latLng = marker.latLng
          this.setState({
            latitude: latLng.lat()
          })
          this.forceUpdate()
          this.setState({
            longitude: latLng.lng()
          })
          this.forceUpdate()
        }.bind(this)
      )

    }
    return (
      <div>

        <div id='branchLocationMap' style={{ height: "500px", width: "100%" }}>

          <div className="card border-info mb-3 z-1 position-absolute" style={({ zIndex: '9999', marginTop: '10px' })}>
            <div className="card-header"><b>Ubicación del cerco virtual de disponibilidad del servicio</b></div>
            <div className="card-body">
              <p className="card-text">Seleccione el radio de disponibilidad de su servicio y de clic en el botón Guardar</p>

              <div className='row text-center'>
                <div className="col-md-6">
                  <label htmlFor="inputRadius"><b>Seleccione el Radio:</b></label>
                </div>
                <div className="col-md-6">&nbsp;</div>
              </div>

              <div className='row'>
                <div className="col-md-5">
                  <select onChange={(event) => this.addCircleRadius(event.target.value)}
                    id='inputRadius' name='inputRadius' className='form-control' value={defaultRadius}>
                    <option value="">Seleccione una opción</option>
                    <option value="1000">1 km</option>
                    <option value="2000">2 km</option>
                    <option value="3000">3 km</option>
                    <option value="4000">4 km</option>
                    <option value="5000">5 km</option>
                    <option value="6000">6 km</option>
                    <option value="7000">7 km</option>
                    <option value="8000">8 km</option>
                    <option value="9000">9 km</option>
                    <option value="10000">10 km</option>
                    <option value="15000">15 km</option>
                    <option value="20000">20 km</option>
                    <option value="25000">25 km</option>
                    <option value="30000">30 km</option>
                    <option value="35000">35 km</option>
                    <option value="40000">40 km</option>
                    <option value="45000">45 km</option>
                    <option value="50000">50 km</option>
                    <option value="60000">60 km</option>
                    <option value="70000">70 km</option>
                    <option value="80000">80 km</option>
                    <option value="90000">90 km</option>
                    <option value="100000">100 km</option>
                    <option value="200000">200 km</option>
                    <option value="300000">300 km</option>
                    <option value="400000">400 km</option>
                    <option value="500000">500 km</option>
                    <option value="1000000">1000 km</option>
                  </select>
                </div>
                <div className="col-md-4 text-center">
                  <button name="reset" type='button' onClick={() => this.resetCircleRadius()} className='btn btn-outline-primary' data-bs-toggle="tooltip" data-bs-placement="top" title="Reiniciar a las coordenadas actuales de la sucursal" >
                    <AiOutlineSync />&nbsp;Resetear
                  </button>
                </div>
                <div className="col-md-3 text-center">
                  <button name="save" type='button' onClick={() => this.addLocationBranch()} className='btn btn-outline-primary' data-bs-toggle="tooltip" data-bs-placement="top" title="Actualizar las coordenadas de la ubicación de la sucursal" >
                    <AiOutlinePushpin />
                    &nbsp;Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <GoogleMap
            bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_API_KEY}` }}
            defaultZoom={zoom}
            center={{ lat: Number(this.props.latitudeBranch), lng: Number(this.props.longitudeBranch) }}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            className="z-2 position-absolute"
          />
        </div>
      </div>
    )
  }
}

// Fin - Mapa

var FormBranches = ({
  id,
  latitudeBranch,
  longitudeBranch,
  radiusBranch,
}) => {

  const { updateBranch } = useBranchesHook({ page: 1, q: '' })
  
  
 

  return (
    <div className="row">
      {updateSuccess ? <Message variant='success'>{updateSuccess}</Message> : ''}

        <GoogleMaps branch_id={`${id}`} updateBranch={updateBranch} latitudeBranch={latitudeBranch} longitudeBranch={longitudeBranch} radiusBranch={radiusBranch} />
      
    </div>
  )
}

export default FormBranches
