import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import FormBranchesMap from '../../../components/branches/FormBranchesMap'

const BranchMaps = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const branch_id = searchParams.get('branch_id')
  const latitude_branch = searchParams.get('latitude_branch')
  const longitude_branch = searchParams.get('longitude_branch')
  const radius_branch = searchParams.get('radius_branch')

  return (
    <>
      <Helmet>
        <title>Sucursales de la Organización</title>
        <meta property='og:title' content='Sucursales' key='title' />
      </Helmet>

      <FormBranchesMap
        id={branch_id}
        latitudeBranch={latitude_branch}
        longitudeBranch={longitude_branch}
        radiusBranch={radius_branch}
      />

    </>
  )
}

export default BranchMaps
