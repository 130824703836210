import { Routes, Route } from 'react-router-dom'
import RequireAuth from './components/RequireAuth'
import ForgotPassword from './screens/auth/ForgotPassword'
import Register from './screens/auth/Register'
import AccountValidation from './screens/auth/AccountValidation'
import AccountValidationUserProfile from './screens/auth/AccountValidationUserProfile'
import Home from './screens/auth/Home'
import Login from './screens/auth/Login'
import ContactForm from './screens/admin/auth/ContactForms'
import NewContactForms from './screens/admin/auth/NewContactForms'
import NotFound from './screens/auth/NotFound'
import ResetPassword from './screens/auth/ResetPassword'
import Profile from './screens/account/Profile'
import ClientPermissions from './screens/admin/auth/ClientPermissions'
import Permissions from './screens/admin/auth/Permissions'
import Services from './screens/admin/auth/Services'
import Roles from './screens/admin/auth/Roles'
import UserRoles from './screens/admin/auth/UserRoles'
import Terminos from './screens/auth/Terminos'
import Privacidad from './screens/auth/Privacidad'
import Users from './screens/admin/auth/Users'
import Organizations from './screens/admin/auth/Organizations'
import Individuals from './screens/admin/auth/Individuals'
import IndividualUser from './screens/admin/auth/IndividualUser'
import IndividualLicenses from './screens/admin/auth/IndividualLicenses'
import MemberUserOrganization from './screens/admin/auth/MemberUserOrganization'
import Licenses from './screens/admin/auth/Licenses'
import EventOrganizations from './screens/admin/auth/EventOrganizations'
import ReportOrganizations from './screens/admin/auth/ReportOrganizations'
import EventIndividuals from './screens/admin/auth/EventIndividuals'
import Networks from './screens/admin/auth/Networks'
import Branches from './screens/admin/auth/Branch'
import BranchesMaps from './screens/admin/auth/BranchMaps'
import BranchLicenses from './screens/admin/auth/BranchLicenses'
import Term from './screens/auth/Term'
import { Layout } from './components'


const App = () => { 
  return (
    <>
      <Layout>
        <Routes>
          <Route path='/auth/login' element={<Login />} />
          
          <Route path='/auth/forgot-password' element={<ForgotPassword />} />
          <Route path='/auth/register' element={<Register />} />
          <Route path='/auth/contact-form' element={<NewContactForms />} />
          <Route path='/auth/reset-password/:resetToken' element={<ResetPassword />} />
          <Route path='/auth/account-validation' element={<AccountValidation />} />
          <Route path='/auth/account-validation-user-profile' element={<AccountValidationUserProfile />} />
          <Route path='/auth/term' element={<Term />} />
          <Route path='/auth/terminos-condiciones' element={<Terminos/>}/>
          <Route path='/auth/aviso-privacidad' element={<Privacidad/>}/>
          <Route element={<RequireAuth />}>
            <Route path='/' element={<Home />} />
            
            <Route path='/admin/auth/client-permissions' element={<ClientPermissions />} />
            <Route path='/account/profile' element={<Profile />} />
            <Route path='/admin/auth/permissions' element={<Permissions />} />
            <Route path='/admin/auth/roles' element={<Roles />} />
            <Route path='/admin/auth/user-roles' element={<UserRoles />} />
            <Route path='/admin/auth/users' element={<Users />} />
            <Route path='/admin/auth/contact-forms' element={<ContactForm />} />  

            <Route path='/admin/auth/organizations' element={<Organizations />} />
            
            <Route path='/admin/auth/branch-licenses' element={<BranchLicenses />} />
            <Route path='/admin/auth/branches' element={<Branches />} />
            <Route path='/admin/auth/branches-maps' element={<BranchesMaps />} />

            <Route path='/admin/auth/event-organizations' element={<EventOrganizations />} />
            <Route path='/admin/auth/event-individuals' element={<EventIndividuals />} />
            <Route path='/admin/auth/member-user-organization' element={<MemberUserOrganization />} />
            <Route path='/admin/auth/report-organizations' element={<ReportOrganizations />} />
            
            <Route path='/admin/auth/licenses' element={<Licenses />} />
      
            <Route path='/admin/auth/services' element={<Services />} />
            <Route path='/admin/auth/individuals' element={<Individuals />} />
            <Route path='/admin/auth/individual-user' element={<IndividualUser />} />
            <Route path='/admin/auth/individual-licenses' element={<IndividualLicenses />} />
            <Route path='/admin/auth/networks' element={<Networks />} />
                  
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Layout>
    </>
  )
}

export default App
