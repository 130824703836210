import { Message } from '..'
import { AiFillProfile, AiOutlineUsergroupAdd, AiOutlineDeploymentUnit, AiOutlineUserAdd, AiOutlineEdit, AiOutlinePicture } from "react-icons/ai"
import { FaPenAlt, FaTrash, FaSearch, FaBroom, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { DatatableWrapper, Filter, Pagination, PaginationOptions, EmptyTablePlaceholder, TableBody, TableHeader } from 'react-bs-datatable'
import { Col, Row, Table } from 'react-bootstrap'
import useTypeCredentialsHook from '../../api/type-credentials'
import useOrganizationsHook from '../../api/organizations'
import useUsersHook from '../../api/users'
import useUploadHook from '../../api/upload'
import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import "yup-phone"
import PhoneInput from 'react-phone-number-input'


var FormOrganizations = ({
  id,
  edit,
  users,
  image,
  formCleanHandler,
  isError,
  isLoadingUpdate,
  error,
  isAdmin,
  organization,
  userId,
}) => {

  const { postOrganization, updateOrganization } = useOrganizationsHook({ page: 1, q: '' })
  const { mutateAsync: mutateAsyncOrganization, error: errorValidations, isLoading: isLoadingUpdateUser } = updateOrganization
  const { isLoading: isLoadingPost, isError:isErrorPost, error: errorPost, mutateAsync: mutateAsyncPost } = postOrganization
  const { postUserByInput } = useUsersHook({ limit: 1, page: 1 })
  const { mutateAsync: mutateAsyncPostSearchUser } = postUserByInput
  const { postUpload } = useUploadHook()
  var [imageUpload, setImageUpload] = useState('')
  const { getTypeCredentials } = useTypeCredentialsHook({})
  const { data: dataTypeCredentials } = getTypeCredentials

  const [typeIdentification, setTypeIdentification] = useState('')
  const [identification, setIdentification] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isPublic, setIsPublic] = useState(false)
  const [typeCredential, setTypeCredential] = useState('')
  const [isActive, setIsActive] = useState(false)

  const headers = [
    { title: 'Nombre del Usuario', prop: 'name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Estado', prop: 'active', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Rol', prop: 'role', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Identificación', prop: `identification`, alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Número Celular', prop: 'phone', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Email', prop: 'email', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Ingreso Contraseña', prop: 'accountValidationInitialPasswordToken', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Bloqueo General', prop: 'blocked', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Acciones', prop: '', alignment: { horizontal: 'center' } }
  ]


  const {
    //data: dataUpload,
    isLoading: isLoadingUpload,
    //isError: isErrorUpload,
    //error: errorUpload,
    mutateAsync: mutateAsyncUpload,
    //isSuccess: isSuccessUpload,
  } = postUpload


  const handleFileImageOrganizationChange = (e) => {
    if (e.target.files) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = async () => {
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        const imageUpload = await mutateAsyncUpload({ type: 'image-organization', formData, id: id })
        setImageUpload(imageUpload.image)
      }
    }
  }


  const addOrganization = async () => {

    await mutateAsyncPost({
      name: name,
      typeIdentification: typeIdentification,
      identification: identification,
      description: description,
      isPublic: isPublic,
      active: isActive,
      typeCredential: typeCredential,
    })
    document.getElementById("close-organization-modal").click()
  }


  const editOrganization = async () => {

    await mutateAsyncPost({
      _id: id,
      name: name,
      typeIdentification: typeIdentification,
      identification: identification,
      description: description,
      isPublic: isPublic,
      active: isActive,
      typeCredential: typeCredential,
    })
  }




  // Metodo al cargar la organizacion se asignan los valores
  useEffect(() => {

    if (edit) {

      setTypeIdentification(organization.typeIdentification)
      setIdentification(organization.identification)
      setName(organization.name)
      setDescription(organization.description)
      setIsPublic(organization.isPublic)

      if (organization.isPublic)
        document.getElementById("isPublic") !=null ? document.getElementById("isPublic").checked = true:false
      else
        document.getElementById("isPublic")!=null? document.getElementById("isPublic").checked = false:false

      setTypeCredential(organization.typeCredential)
      setIsActive(organization.active)

      if (organization.active) {
        if (document.getElementById("isPublic") != null)
          document.getElementById("isActive").checked = true 
      } else {
        if (document.getElementById("isPublic") != null)
          document.getElementById("isActive").checked = false
      }
    } else {
      setTypeIdentification('')
      setIdentification('')
      setName('')
      setDescription('')
      setIsPublic(false)
      if (document.getElementById("isPublic") != null)
        document.getElementById("isPublic").checked = false
      setTypeCredential('')
      setIsActive(false)
      if (document.getElementById("isActive") != null)
        document.getElementById("isActive").checked = false
    }
  }, [edit])



  useEffect(() => {
    setImageUpload(image)
  }, [image])

  // Inicio - User
  const [idUser, setIdUser] = useState('')
  const [typeIdentificationUser, setTypeIdentificationUser] = useState(undefined)
  const [identificationUser, setIdentificationUser] = useState('')
  const [nameUser, setNameUser] = useState('')
  const [lastNameUser, setLastNameUser] = useState('')
  const [emailUser, setEmailUser] = useState('')
  const [phoneNumberUser, setPhoneNumberUser] = useState()
  const [sexUser, setSexUser] = useState('')
  const [birthdayUser, setBirthdayUser] = useState('')
  const [editUser, setEditUser] = useState(false)
  const [inputSearchUser, setInputSearchUser] = useState('')
  const [isActiveUser, setIsActiveUser] = useState(false)
  const [roleUser, setRoleUser] = useState('')
  const [isDisabledUser, setIsDisabledUser] = useState(true)
  const [isDisabledUserEdit, setIsDisabledUserEdit] = useState(true)
  const [errorsListUser, setErrorsListUser] = useState('')


  const cleanUser = async () => {

    setInputSearchUser('')
    setIsDisabledUser(true)
    setIsDisabledUserEdit(true)
    setIdUser('')
    setTypeIdentificationUser('')
    setIdentificationUser('')
    setBirthdayUser('')
    setSexUser('')
    setNameUser('')
    setLastNameUser('')
    setEmailUser('')
    setPhoneNumberUser('')
    setIsActiveUser(false)
    setRoleUser('')
    setEditUser(false)
    setErrorsListUser('')
    document.getElementById('isActiveUser').removeAttribute('checked')

  }

  const addUser = async () => {


    let formatDateBirthday = ''
    // Validacion de la fecha por conversion del componente web
    if (birthdayUser != '') {
      let dateArray = birthdayUser.split('-')
      formatDateBirthday = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
    }
    const branchUpdate = await mutateAsyncOrganization({
      _id: id,
      name: '',
      typeIdentification: '',
      identification: '',
      description: '',
      isPublic: '',
      active: '',
      users: {
        _id: idUser,
        typeIdentification: typeIdentificationUser,
        identification: identificationUser,
        birthday: formatDateBirthday,
        sex: sexUser,
        name: nameUser,
        lastname: lastNameUser,
        email: emailUser,
        phone: phoneNumberUser,
        role: roleUser,
        active: isActiveUser
      },
    })

    users.splice(0)

    for (let i = 0; i < branchUpdate.users.length; i++) {
      users.push({
        _id: branchUpdate.users[i].user._id,
        typeIdentification: branchUpdate.users[i].user.typeIdentification,
        identification: branchUpdate.users[i].user.identification,
        birthday: branchUpdate.users[i].user.birthday,
        sex: branchUpdate.users[i].user.sex,
        name: branchUpdate.users[i].user.name,
        lastname: branchUpdate.users[i].user.lastname,
        email: branchUpdate.users[i].user.email,
        phone: branchUpdate.users[i].user.phone,
        accountValidationEmailToken: branchUpdate.users[i].user.accountValidationEmailToken,
        accountValidationPhoneToken: branchUpdate.users[i].user.accountValidationPhoneToken,
        accountValidationInitialPasswordToken: branchUpdate.users[i].user.accountValidationInitialPasswordToken,
        active: branchUpdate.users[i].active,
        role: branchUpdate.users[i].typeUser
      })
    }

    cleanUser()
    document.getElementById("close-user-organization").click()
  }

  const editUserFunction = (idUser) => {
    setEditUser(true)
    setIsDisabledUser(true)
    setIsDisabledUserEdit(false)
    var userFound = users.find((e) => e._id == idUser)

    setIdUser(userFound._id)
    setTypeIdentificationUser(userFound.typeIdentification)
    setIdentificationUser(userFound.identification)
    setBirthdayUser(userFound.birthday != undefined ? userFound.birthday.substring(0, 10) : '')
    setSexUser(userFound.sex)
    setNameUser(userFound.name)
    setLastNameUser(userFound.lastname)
    setEmailUser(userFound.email)
    setPhoneNumberUser(userFound.phone)
    setIsActiveUser(userFound.active)
    setRoleUser(userFound.role == 'Admin' ? 'ADMIN_ORGANIZATION' : 'MONITOR_ORGANIZATION')
    if (userFound.active)
      document.getElementById("isActiveUser").checked = true
    else
      document.getElementById("isActiveUser").checked = false

  }

  const schemaSearchUser = Yup.object().shape({
    search: Yup.string().required("* Ingrese un correo electrónico, un número telefónico o una identificación"),
  })

  const deleteUserFunction = async (idUser) => {

    const branchUpdate = await mutateAsyncOrganization({
      _id: id,
      users: {
        _id: idUser,
      },
      deleteUserId: idUser,
    })

    users.splice(0)

    for (let i = 0; i < branchUpdate.users.length; i++) {
      users.push({
        _id: branchUpdate.users[i].user._id,
        typeIdentification: branchUpdate.users[i].user.typeIdentification,
        identification: branchUpdate.users[i].user.identification,
        birthday: branchUpdate.users[i].user.birthday,
        sex: branchUpdate.users[i].user.sex,
        name: branchUpdate.users[i].user.name,
        lastname: branchUpdate.users[i].user.lastname,
        email: branchUpdate.users[i].user.email,
        phone: branchUpdate.users[i].user.phone,
        accountValidationEmailToken: branchUpdate.users[i].user.accountValidationEmailToken,
        accountValidationPhoneToken: branchUpdate.users[i].user.accountValidationPhoneToken,
        accountValidationInitialPasswordToken: branchUpdate.users[i].user.accountValidationInitialPasswordToken,
        active: branchUpdate.users[i].active,
        role: branchUpdate.users[i].typeUser
      })
    }

  }

  const searchUser = async () => {

    if (inputSearchUser == '') {
      setErrorsListUser('Ingrese un valor de búsqueda')
      return false
    }

    const retorno = await mutateAsyncPostSearchUser({ input: inputSearchUser })
    var errorMessage = ''
    cleanUser()

    if (retorno._id == undefined) {
      errorMessage = '* No se ha encontrado ningún usuario. Ingrese un nuevo usuario'
      setErrorsListUser(errorMessage)
      setEditUser(false)
      setIsDisabledUser(false)
      setIsDisabledUserEdit(false)

    } else {
      setEditUser(true)
      setIsDisabledUser(true)
      setIsDisabledUserEdit(false)
    }

    setIdUser(retorno && retorno._id != undefined ? retorno._id : '')
    setTypeIdentificationUser(retorno && retorno.typeIdentification != undefined ? retorno.typeIdentification : '')
    setIdentificationUser(retorno && retorno.identification != undefined ? retorno.identification : '')
    setBirthdayUser(retorno && retorno.birthday != undefined ? retorno.birthday.substring(0, 10) : '')
    setSexUser(retorno && retorno.sex != undefined ? retorno.sex : '')
    setNameUser(retorno && retorno.name != undefined ? retorno.name : '')
    setLastNameUser(retorno && retorno.lastname != undefined ? retorno.lastname : '')
    setEmailUser(retorno && retorno.email != undefined ? retorno.email : '')
    setPhoneNumberUser(retorno && retorno.phone != undefined ? retorno.phone : '')
    setIsActiveUser(retorno && retorno.active != undefined ? retorno.active : false)
  }

  const formValidationSearchUser = async () => {
    let validacion = undefined
    try {

      validacion = await schemaSearchUser.validate({ search: inputSearchUser })
    }
    catch (errors) {
      setErrorsListUser(errors.message)
    }

    if (validacion !== '') {
      searchUser()
    }
  }

  // Fin - User


  // Mostrar el nombre del tipo de credencial por medio del Id
  const showNameTypeCredential = (typeCredentialId) => {

    if (typeCredentialId != undefined && typeCredentialId != '') {
      var typeCredentialFound = dataTypeCredentials.data.find((e) => e._id == typeCredentialId)
      if (typeCredentialFound != undefined) {
        return typeCredentialFound.name
      } else {
        return ''
      }

    }
  }

  return (
    <div
      className='modal fade'
      id='organizationModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='organizationModalLabel'
      aria-hidden='true'
    >

      {errorValidations && <Message variant='danger'>{errorValidations}</Message>}
      {isErrorPost && <Message variant='danger'>{errorPost}</Message>}

      <div className='modal-dialog modal-dialog-grande'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='organizationModalLabel'>
              {edit ? 'Editar Organización' : 'Crear Organización'}
            </h3>
            <button
              type='button'
              id='close-organization-modal'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>

            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" id="icon-tab-0" data-bs-toggle="tab" href="#icon-tabpanel-0" role="tab" aria-controls="icon-tabpanel-0" aria-selected="true"><AiFillProfile /> Datos Generales</a>
              </li>
              <li className="nav-item" role="presentation" style={{ display: edit === true ? 'block' : 'none' }}>
                <a className="nav-link" id="icon-tab-1" data-bs-toggle="tab" href="#icon-tabpanel-1" role="tab" aria-controls="icon-tabpanel-1" aria-selected="false"><AiOutlineDeploymentUnit /> Sucursales </a>
              </li>
              <li className="nav-item" role="presentation" style={{ display: edit === true ? 'block' : 'none' }}>
                <a className="nav-link" id="icon-tab-2" data-bs-toggle="tab" href="#icon-tabpanel-2" role="tab" aria-controls="icon-tabpanel-2" aria-selected="false"><AiOutlineUsergroupAdd /> Administradores de Organización</a>
              </li>
            </ul>
            <div className="tab-content pt-3" id="tab-content">
              <div className="tab-pane active" id="icon-tabpanel-0" role="tabpanel" aria-labelledby="icon-tab-0">
                {isError ? (<Message variant='danger'>{error}</Message>) : ''}
                <div className='row'>
                  <div className={edit ? 'col-md-6' : 'col-md-12'} >
                    <div className='row'>
                      <div className='col-md-6 col-12'>
                        {isAdmin ? (
                          <div>
                            <label htmlFor="typeIdentification"><b>Tipo de identificación:</b></label>
                            <select onChange={(event) => setTypeIdentification(event.target.value)}
                              id='typeIdentification' name='typeIdentification' className='form-control' value={typeIdentification}>
                              <option value="" >Seleccione una opción</option>
                              <option key="RUC" >RUC</option>
                            </select>
                          </div>) : <div><label htmlFor='typeIdentification'><b>Tipo de identificación:</b></label><br></br><label id='typeIdentification' className='form-control' >{typeIdentification}</label></div>}
                      </div>
                      <div className='col-md-6 col-12'>
                        {isAdmin ? (
                          <div>
                            <label htmlFor="identification"><b>Identificación:</b></label>
                            <input
                              type='text'
                              name='identification'
                              value={identification}
                              onInput={(event) => event.target.value=event.target.value.replace(/[^0-9]/g,'').slice(0,13)}
                              onChange={(event) => setIdentification(event.target.value)}
                              className='form-control' />
                          </div>) : <div><label htmlFor='identification'><b>Identificación:</b></label><br></br><label id='identification' className='form-control' >{identification}</label></div>}
                      </div>
                      <div className='col-md-6 col-12'>
                        {isAdmin ? (
                          <div>
                            <label htmlFor="name"><b>Nombres:</b></label>
                            <input
                              type='text'
                              id='name'
                              name='name' 
                              value={name}
                              onChange={(event) => setName(event.target.value)}
                              className='form-control' />
                          </div>) : <div><label htmlFor='name'><b>Nombres:</b></label><br></br><label id='name' className='form-control' >{name}</label></div>}
                      </div>
                      <div className='col-md-6 col-12'>
                        {isAdmin ? (
                          <div>
                            <label htmlFor="description"><b>Descripción:</b></label>
                            <input
                              type='text'
                              id='description'
                              name='description'
                              value={description}
                              onChange={(event) => setDescription(event.target.value)}
                              className='form-control' />
                          </div>) : <div><label htmlFor='description'><b>Descripción:</b></label><br></br><label id='description' className='form-control' >{description}</label></div>}
                      </div>
                      <div className='col-md-6 col-12'>
                        {isAdmin ? (
                          <div>
                            <label htmlFor="isPublic"><b>Es Público?:</b></label>
                            <div className='form-check form-switch form-control'>
                              <input className='form-check-input'
                                type='checkbox'
                                id='isPublic'
                                name='isPublic'
                                value={isPublic}
                                onChange={(event) => setIsPublic(event.target.checked)}

                              />
                              <label className='form-check-label' htmlFor='isPublic'>
                                Público
                              </label>
                            </div>
                          </div>) : isPublic ? (<div>
                            <label htmlFor="isPublic"><b>Es Público?:</b></label><div className='form-control'><FaCheckCircle className='text-success' /></div></div>) : (<div>
                              <label htmlFor="isPublic"><b>Es Público?:</b></label><div className='form-control'><FaTimesCircle className='text-danger' /></div></div>)}
                      </div>
                      <div className='col-md-6 col-12'>
                        {isAdmin ? (
                          <div>
                            <label htmlFor="typeCredential"><b>Tipo de Credencial Acceso:</b></label>
                            <select onChange={(event) => setTypeCredential(event.target.value)}

                              id='typeCredential' name='typeCredential' className='form-control' value={typeCredential}>
                              <option key="" value="" >Seleccione una opción</option>
                              {dataTypeCredentials && dataTypeCredentials.data.map((credentialType) => (
                                <option key={credentialType._id} value={credentialType._id} >{credentialType.name}</option>
                              ))}
                            </select>
                          </div>) : <div>
                          <label htmlFor="typeCredential"><b>Tipo de Credencial Acceso:</b></label><label className='form-control'>{showNameTypeCredential(typeCredential)}</label></div>}
                      </div>
                      <div className='col-md-6 col-12'>
                        {isAdmin ? (
                          <div>
                            <label htmlFor="isActive"><b>Activo?:</b></label>
                            <div className='form-check form-switch form-control'>
                              <input className='form-check-input'
                                type='checkbox'
                                id='isActive'
                                name='isActive'
                                value={isActive}
                                onChange={(event) => setIsActive(event.target.checked)}
                              />
                              <label className='form-check-label' htmlFor='isActive'>
                                Activo
                              </label>
                            </div>
                          </div>) : isActive ? (<div>
                            <label htmlFor="isActive"><b>Activo?:</b></label><div className='form-control'><FaCheckCircle className='text-success' /></div></div>) : (<div>
                              <label htmlFor="isActive"><b>Activo?:</b></label><div className='form-control'><FaTimesCircle className='text-danger' /></div></div>)}
                      </div>

                    </div>
                    <br></br>
                    {isAdmin ? (
                      <div className='row'>
                        <div className="col-md-12 text-center">
                          <button
                            type='button'
                            className='btn btn-primary '
                            onClick={edit ? editOrganization : addOrganization}
                            data-bs-toggle="tooltip" data-bs-placement="top" title={edit ? 'Actualizar Organización' : 'Crear Organización'}
                          >
                            <AiOutlineEdit /> &nbsp; {isLoadingPost || isLoadingUpdate ? (
                              <span className='spinner-border spinner-border-sm' />
                            ) : '' }
                            &nbsp;&nbsp;
                            {edit ? 'Actualizar' : 'Crear '}
                          </button>
                        </div>
                      </div>) : ''}
                  </div>
                  {edit ? (
                    <div className='col-md-6 '>
                      <div className='row'>

                        <div className='row'>
                          <div className='col-md-4'></div>
                          <div className='col-md-4 text-center'>
                            <img
                              src={imageUpload}
                              width='150'
                              height='80'
                            />
                          </div>
                          <div className='col-md-4'></div>
                        </div>

                        <div className='row'>
                          <div className='col-md-12'>
                            <label htmlFor="file" className="sr-only">
                              <center>* Suba la imagen de la Organización. Tamaño Ancho:150px - Alto:80px</center>
                            </label>
                          </div>
                        </div>
                        <br></br>

                        <div className='row'>

                          <div className='col-md-4 '>

                          </div>
                          <div className='col-md-4 text-center'>
                            <label htmlFor="image" className="btn btn-primary btn-block btn-outlined" data-bs-toggle="tooltip" data-bs-placement="top" title="Subir Imagen de la Organización">
                              {isLoadingUpload ? (
                                <span className='spinner-border spinner-border-sm' />
                              ) : (
                                ''
                              )}&nbsp;
                              <AiOutlinePicture /> &nbsp;
                              Subir imagen
                            </label>
                            <input id="image" type="file" accept="image/*" onChange={handleFileImageOrganizationChange} className='form-control' style={{ display: 'none' }} />
                          </div>
                          <div className='col-md-4 '>

                          </div>


                        </div>
                      </div>
                    </div>) : ''}

                </div>
              </div>
              <div className="tab-pane" id="icon-tabpanel-1" role="tabpanel" aria-labelledby="icon-tab-1">


                <iframe src={`/admin/auth/branches?organization_id=${id}&user_id=${userId}`} width="100%" height="1200px" ></iframe>

              </div>
              <div className="tab-pane" id="icon-tabpanel-2" role="tabpanel" aria-labelledby="icon-tab-2">
                <center><a className="btn btn-primary" href="#open-modal-user" onClick={() => cleanUser()} data-bs-toggle="tooltip" data-bs-placement="top" title="Clic para agregar un usuario"> <AiOutlineUserAdd /> &nbsp;Agregar un Usuario</a></center>
                <br></br>

                <DatatableWrapper body={users} headers={headers} paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 15,
                    options: [5, 10, 15, 20]
                  }
                }} >
                  <Row className="mb-4">
                    <Col
                      xs={12}
                      lg={4}
                      className="d-flex flex-col justify-content-end align-items-end"
                    >
                      <Filter placeholder={'Ingrese información del usuario a encontrar'} />
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      lg={4}
                      className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                      <PaginationOptions labels={{ beforeSelect: `Filas por página / Usuarios encontrados ${users.length}` }} />
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      lg={4}
                      className="d-flex flex-col justify-content-end align-items-end"
                    >
                      <Pagination labels={{ firstPage: 'Inicio', lastPage: 'Final', nextPage: 'Siguiente', prevPage: 'Anterior' }} />
                    </Col>
                  </Row>
                  <hr></hr>
                  <Table>
                    <TableHeader />
                    <TableBody>
                      {(rows) =>
                        rows.length === 0 ? (
                          <EmptyTablePlaceholder noResultsLabel={'Usuarios no encontrados'} />
                        ) : (
                          rows.map((rowData, rowIdx) => (
                            <tr key={rowIdx} className='text-center'>
                              <td>{rowData.name} {rowData.lastname}</td>
                              <td>
                                {rowData.active ? (
                                  <FaCheckCircle className='text-success' />
                                ) : (
                                  <FaTimesCircle className='text-danger' />
                                )}

                              </td>
                              <td>{rowData.role}</td>
                              <td>{rowData.identification}</td>
                              <td>
                                {rowData.accountValidationPhoneToken == '' ? (
                                  <FaCheckCircle className='text-success' />
                                ) : (
                                  <FaTimesCircle className='text-danger' />
                                )} &nbsp;&nbsp;{rowData.phone}
                              </td>
                              <td>
                                {rowData.accountValidationEmailToken == '' ? (
                                  <FaCheckCircle className='text-success' />
                                ) : (
                                  <FaTimesCircle className='text-danger' />
                                )}&nbsp;&nbsp;{rowData.email}
                              </td>
                              <td>
                                {rowData.accountValidationInitialPasswordToken == undefined ? (
                                  <FaCheckCircle className='text-success' />
                                ) : (
                                  <FaTimesCircle className='text-danger' />
                                )}
                              </td>
                              <td>
                                {rowData.blocked ? (
                                  <FaCheckCircle className='text-success' />
                                ) : (
                                  <FaTimesCircle className='text-danger' />
                                )}
                              </td>
                              <td>
                                <div className='btn-group'>

                                  <a className="btn btn-primary btn-sm rounded-pill" href="#open-modal-user" onClick={() => editUserFunction(rowData._id)} data-bs-toggle="tooltip" data-bs-placement="top" title="Editar Usuario"> <FaPenAlt /></a>
                                  &nbsp;
                                  <button className='btn btn-danger btn-sm ms-1 rounded-pill' data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar Usuario" onClick={() => { if (window.confirm('Desea eliminar el usuario de admistradores de la Organización ?')) deleteUserFunction(rowData._id) }}>
                                    <span><FaTrash /></span>
                                  </button>

                                </div>
                              </td>
                            </tr>

                          )
                          )
                        )
                      }
                    </TableBody>
                  </Table>
                </DatatableWrapper>

                <div id="open-modal-user" className="modal-window-external" >
                  <div>
                    <b><h5 >
                      {editUser ? 'Editar Usuario' : 'Agregar Usuario'}
                    </h5></b>
                    <a href="#" id="close-user-organization" title="Close" className="modal-close">&#x2715;</a>
                    <hr></hr>
                    <div className='row'>
                      <div className="col-md-12">

                        {editUser == false ? (
                          <div className='row'>
                            <div className="row">
                              <div className="col-md-2">
                                <label><b>Búsqueda:</b></label>
                              </div>
                              <div className="col-md-8">
                                <input
                                  type='text'
                                  name='inputSearchUser'
                                  placeholder='Usuarios por identificación, email o teléfono'
                                  value={inputSearchUser}
                                  style={({ width: '100%' })}
                                  onChange={(event) => setInputSearchUser(event.target.value)}
                                />
                              </div>
                              <div className="col-md-2">
                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Buscar">
                                  <button type='button' className='btn btn-primary btn-sm rounded-pill'
                                    onClick={() => formValidationSearchUser()}>
                                    <FaSearch />
                                  </button>
                                </span>
                                &nbsp;
                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip" title="Limpiar">
                                  <button className='btn btn-primary btn-sm rounded-pill'
                                    onClick=
                                    {() => cleanUser()}>
                                    <FaBroom />
                                  </button>
                                </span>
                              </div>
                            </div>
                            &nbsp;
                            <div className='row text-center'>
                              {errorsListUser ? (<span className='text-danger'>{errorsListUser}</span>) : ''}
                            </div>
                            <br></br>
                            <hr></hr>
                          </div>

                        ) : ''}

                      </div>
                    </div>

                    <div className='row'>

                      {editUser ? (
                        <div className="col-md-12">

                          <div className="alert alert-primary text-center" role="alert">
                            Editar un usuario solo se puede realizar desde el perfil de cada usuario
                          </div>
                        </div>) : ''}
                      {editUser == false ? (
                        <div className="col-md-6">
                          <label htmlFor="typeIdentificationUser"><b>Tipo Identificación:</b></label>
                          <select onChange={(event) => setTypeIdentificationUser(event.target.value)} disabled={isDisabledUser}
                            name='typeIdentificationUser' className='form-control' value={typeIdentificationUser}>
                            <option value="" >Seleccione una opción</option>
                            <option value="RUC" >RUC</option>
                            <option value="Cedula" >Cedula</option>
                            <option value="Pasaporte" >Pasaporte</option>
                          </select>
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Tipo Identificación:</b></label><br></br>
                          <label>{typeIdentificationUser == '' ? 'No ha ingresado' : typeIdentificationUser}</label>
                          <br></br>
                        </div>)}

                      {editUser == false ? (
                        <div className="col-md-6">
                          <label htmlFor="identificationUser"><b>Identificación:</b></label>
                          <input
                            type='text'
                            name='identificationUser'
                            value={identificationUser}
                            onChange={(event) => setIdentificationUser(event.target.value)}
                            className='form-control'
                            disabled={isDisabledUser}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Identificación:</b></label><br></br>
                          <label>{identificationUser == '' ? 'No ha ingresado' : identificationUser}</label>
                          <br></br>
                        </div>)}

                      {editUser == false ? (
                        <div className="col-md-6">
                          <label htmlFor="nameUser"><b>Nombres:</b></label>
                          <input
                            type='text'
                            name='nameUser'
                            value={nameUser}
                            onChange={(event) => setNameUser(event.target.value)}
                            className='form-control'
                            disabled={isDisabledUser}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Nombres:</b></label><br></br>
                          <label>{nameUser == '' ? 'No ha ingresado' : nameUser}</label>
                          <br></br>
                        </div>)}

                      {editUser == false ? (
                        <div className="col-md-6">
                          <label htmlFor="lastNameUser"><b>Apellidos:</b></label>
                          <input
                            type='text'
                            name='lastNameUser'
                            value={lastNameUser}
                            onChange={(event) => setLastNameUser(event.target.value)}
                            className='form-control'
                            disabled={isDisabledUser}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Apellidos:</b></label><br></br>
                          <label>{lastNameUser == '' ? 'No ha ingresado' : lastNameUser}</label>
                          <br></br>
                        </div>)}

                      {editUser == false ? (
                        <div className="col-md-6">
                          <label htmlFor="emailUser"><b>Correo Electrónico:</b></label>
                          <input
                            type='email'
                            name='emailUser'
                            value={emailUser}
                            onChange={(event) => setEmailUser(event.target.value)}
                            className='form-control'
                            disabled={isDisabledUser}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Correo Electrónico:</b></label><br></br>
                          <label>{emailUser == '' ? 'No ha ingresado' : emailUser}</label>
                          <br></br>
                        </div>)}

                      {editUser == false ? (
                        <div className="col-md-6">
                          <label htmlFor="phoneNumberUser"><b>Teléfono:</b></label>
                          <PhoneInput
                            name="phoneNumberUser"
                            placeholder="Ingrese un número telefónico"
                            value={phoneNumberUser}
                            onChange={setPhoneNumberUser}
                            className='form-control'
                            disabled={isDisabledUser}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Teléfono:</b></label><br></br>
                          <label>{phoneNumberUser == '' ? 'No ha ingresado' : phoneNumberUser}</label>
                          <br></br>
                        </div>)}

                      {editUser == false ? (
                        <div className="col-md-6">
                          <label htmlFor="sexUser"><b>Sexo:</b></label>
                          <select onChange={(event) => setSexUser(event.target.value)} disabled={isDisabledUser}
                            name='sexUser' className='form-control' value={sexUser}>
                            <option value="" >Seleccione una opción</option>
                            <option value="Masculino" >Masculino</option>
                            <option value="Femenino" >Femenino</option>
                          </select>
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>Sexo:</b></label><br></br>
                          <label>{sexUser == '' ? 'No ha ingresado' : sexUser}</label>
                          <br></br>
                        </div>)}

                      {editUser == false ? (
                        <div className="col-md-6">
                          <label htmlFor="birthdayUser"><b>F. Nacimiento:</b></label>
                          <input
                            type='date'
                            name='birthdayUser'
                            value={birthdayUser}
                            onChange={(event) => setBirthdayUser(event.target.value)}
                            className='form-control'
                            disabled={isDisabledUser}
                          />
                        </div>) : (
                        <div className="col-md-6">
                          <label><b>F. Nacimiento:</b></label><br></br>
                          <label>{birthdayUser == '' ? 'No ha ingresado' : birthdayUser}</label>
                          <br></br>
                        </div>)}
                    </div>
                    {editUser ? (<hr></hr>) : ''}

                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="roleUser"><b>Rol:</b></label>
                        <select onChange={(event) => setRoleUser(event.target.value)} disabled={isDisabledUserEdit}
                          name='roleUser' className='form-control' value={roleUser}>
                          <option value="" >Seleccione una opción</option>
                          <option value="ADMIN_ORGANIZATION" >Admin</option>
                          <option value="MONITOR_ORGANIZATION" >Monitor</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="isActiveUser"><b>Estado:</b></label>
                        <div className='form-check form-switch form-control'>
                          <input className='form-check-input'
                            type='checkbox'
                            id='isActiveUser'
                            name='isActiveUser'
                            value={isActiveUser}
                            onChange={(event) => setIsActiveUser(event.target.checked)}
                            disabled={isDisabledUserEdit}
                          />
                          <label className='form-check-label' htmlFor='isActiveUser'>
                            Activo
                          </label>
                        </div>
                      </div>
                      <div className='row'>&nbsp;</div>
                      <div className='row col-md-12'>
                        <div className="col-md-4">
                          <p style={({ display: 'none' })}>{idUser}</p>
                        </div>
                        <div className="col-md-4">
                          <button type='button' onClick={addUser} className='btn btn-primary form-control' disabled={isDisabledUserEdit} data-bs-toggle="tooltip" data-bs-placement="top" title={isDisabledUserEdit == false ? ' Actualizar Organización ' : ' Crear Organización'} >
                            <AiOutlineUserAdd /> &nbsp;

                            {isLoadingUpdateUser ? (
                              <span className='spinner-border spinner-border-sm' />
                            ) : ('')}

                            {isDisabledUserEdit == false ? ' Actualizar' : ' Crear '}

                          </button>
                        </div>
                        <div className="col-md-4">

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default FormOrganizations
