import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import useBranchesHook from '../../../api/branches'
import { useLocation } from 'react-router-dom'

import FormBranches from '../../../components/branches/FormBranches'
import ViewBranches from '../../../components/branches/ViewBranches'

import {
  Message,
} from '../../../components'


const Branches = () => {
  const [page, setPage] = useState(1)
  const [id, setId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')
  const [users, setUsers] = useState([])
  const [members, setMembers] = useState([])
  var [latitudeBranch, setLatitudeBranch] = useState(0)
  var [longitudeBranch, setLongitudeBranch] = useState(0)
  var [radiusBranch, setRadiusBranch] = useState(0)
  var [userRadius, setUserRadius] = useState(0)
  var [userLatitude, setUserLatitude] = useState(0)
  var [userLongitude, setUserLongitude] = useState(0)


  var usersList = []
  var membersList = []

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const organization_id = searchParams.get('organization_id')




  var { getBranches, postBranch, updateBranch, deleteBranch } =
    useBranchesHook({
      page,
      q,
    })

  var {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: true,
    },
  })


  var { data, isLoading, isError, error, refetch } = getBranches

  var {
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    error: errorUpdate,
    isSuccess: isSuccessUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = updateBranch

  var {
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    error: errorDelete,
    isSuccess: isSuccessDelete,
    mutateAsync: mutateAsyncDelete,
  } = deleteBranch

  var {
    isLoading: isLoadingPost,
    isError: isErrorPost,
    error: errorPost,
    isSuccess: isSuccessPost,
    mutateAsync: mutateAsyncPost,
  } = postBranch



  var formCleanHandler = () => {
    setEdit(false)
    reset()
    setValue('name', '')
    setValue('isPublic', false)
    setValue('active', false)
    document.getElementById("icon-tab-0") != undefined ? document.getElementById("icon-tab-0").click() : ''
  }




  useEffect(() => {
    if (isSuccessPost) formCleanHandler()
    refetch()

  }, [isSuccessPost, isSuccessUpdate])


  useEffect(() => {
    refetch()
  }, [page])


  useEffect(() => {
    if (!q) refetch()
  }, [q])



  useEffect(() => {

    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLatitude(position.coords.latitude)
          setUserLongitude(position.coords.longitude)
          setUserRadius(10000)
        },
        // if there was an error getting the users location
        (error) => {
          console.error('Error getting user location:', error);
        }
      )
    }
    // if geolocation is not supported by the users browser
    else {
      console.error('Geolocation is not supported by this browser.');
    }

  }, [userLatitude])


  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  const deleteHandler = (id) => {
    mutateAsyncDelete(id)
  }

  const submitHandler = (data) => {

    edit
      ? mutateAsyncUpdate({
        _id: id,
        name: data.name,
        isPublic: data.isPublic,
        active: data.active,
        type: 'update_branch'
      })
      : mutateAsyncPost({
        name: data.name,
        active: data.active,
        isPublic: data.isPublic,
        organization_id: organization_id
      })
  }


  const editHandler = (branch) => {
    //refetch()
    setId(branch._id)
    setEdit(true)
    setValue('name', branch.name)
    setValue('isPublic', branch.isPublic)
    setValue('active', branch.active)


    setLatitudeBranch(branch.location != undefined ? branch.location.coordinates[0] : userLatitude)
    setLongitudeBranch(branch.location != undefined ? branch.location.coordinates[1] : userLongitude)
    setRadiusBranch(branch.location != undefined ? branch.location.radius : userRadius)



    for (let i = 0; i < branch.users.length; i++) {
      usersList.push({
        _id: branch.users[i].user._id,
        typeIdentification: branch.users[i].user.typeIdentification,
        identification: branch.users[i].user.identification,
        birthday: branch.users[i].user.birthday,
        sex: branch.users[i].user.sex,
        name: branch.users[i].user.name,
        lastname: branch.users[i].user.lastname,
        email: branch.users[i].user.email,
        phone: branch.users[i].user.phone,
        accountValidationEmailToken: branch.users[i].user.accountValidationEmailToken,
        accountValidationPhoneToken: branch.users[i].user.accountValidationPhoneToken,
        accountValidationInitialPasswordToken: branch.users[i].user.accountValidationInitialPasswordToken,
        accountValidationInitialPasswordDate: branch.users[i].user.accountValidationInitialPasswordDate,
        active: branch.users[i].active,
        role: branch.users[i].typeUser,
      })
    }

    for (let i = 0; i < branch.members.length; i++) {
      membersList.push({
        _id: branch.members[i].user._id,
        typeIdentification: branch.members[i].user.typeIdentification,
        identification: branch.members[i].user.identification,
        birthday: branch.members[i].user.birthday,
        sex: branch.members[i].user.sex,
        name: branch.members[i].user.name,
        lastname: branch.members[i].user.lastname,
        email: branch.members[i].user.email,
        phone: branch.members[i].user.phone,
        accountValidationEmailToken: branch.members[i].user.accountValidationEmailToken,
        accountValidationPhoneToken: branch.members[i].user.accountValidationPhoneToken,
        accountValidationInitialPasswordToken: branch.members[i].user.accountValidationInitialPasswordToken,
        accountValidationInitialPasswordDate: branch.members[i].user.accountValidationInitialPasswordDate,
        active: branch.members[i].active,
        network: branch.members[i].network,
        branch: branch._id,
        blocked: branch.members[i].user.blocked,
        disabledByLicense: branch.members[i].disabledByLicense
      })
    }
    setUsers(usersList)
    setMembers(membersList)
  }

  return (
    <>
      <Helmet>
        <title>Sucursales de la Organización</title>
        <meta property='og:title' content='Sucursales' key='title' />
      </Helmet>
      {isSuccessDelete && (
        <Message variant='success'>
          Sucursal eliminada correctamente.
        </Message>
      )}
      {isErrorDelete && <Message variant='danger'>{errorDelete}</Message>}

      {isErrorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
      {isSuccessPost && (
        <Message variant='success'>
          Sucursal creada correctamente.
        </Message>
      )}
      {isErrorPost && <Message variant='danger'>{errorPost}</Message>}
      {isError && <Message variant='danger'>{error}</Message>}

      <FormBranches
        id={id}
        users={users}
        members={members}
        edit={edit}
        formCleanHandler={formCleanHandler}
        isLoading={isLoading}
        isError={isError}
        errors={errors}
        isLoadingUpdate={isLoadingUpdate}
        isLoadingPost={isLoadingPost}
        register={register}
        handleSubmit={handleSubmit}
        submitHandler={submitHandler}
        watch={watch}
        error={error}
        isSuperAdmin={data != undefined ? data.isSuperAdmin : false}
        latitudeBranch={latitudeBranch}
        longitudeBranch={longitudeBranch}
        radiusBranch={radiusBranch}
      />

      {organization_id != '' ? (
        <ViewBranches
          data={data}
          editHandler={editHandler}
          formCleanHandler={formCleanHandler}
          deleteHandler={deleteHandler}
          isLoadingDelete={isLoadingDelete}
          setQ={setQ}
          q={q}
          searchHandler={searchHandler}
          isSuperAdmin={data != undefined ? data.isSuperAdmin : false}
        />) : ''
      }

    </>
  )
}

export default Branches
