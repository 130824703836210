import { useEffect, useState } from 'react'
import { FormContainer, Message } from '../../components'
import { useNavigate, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useAuthHook from '../../api/auth'
import useAuth from '../../hooks/useAuth'
import { Helmet } from 'react-helmet'
import { AiOutlineMail, AiOutlineHome  } from "react-icons/ai"

const ForgotPassword = () => {
  useAuth()
  const { postForgotPassword } = useAuthHook()
  const navigate = useNavigate()
  const { auth } = useAuth()
  const [identification, setIdentification] = useState('')
  const [message, setMessage] = useState('')

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const { isLoading, isError, error, isSuccess, mutateAsync } =
    postForgotPassword

  useEffect(() => {
    isSuccess && reset()
  }, [isSuccess])

  useEffect(() => {
    auth?.userInfo && navigate('/')
  }, [navigate])

  const submitHandler = async () => {


    const retorno =  await mutateAsync({
      identification: identification,
    })
    console.log(retorno)
    if(retorno.message != undefined) {
      setMessage(retorno.message)
    }    
  }
  return (
    <FormContainer>
      <Helmet>
        <title>Olvidó su Contraseña / Validación de Cuenta</title>
        <meta property='og:title' content='Forgot' key='title' />
      </Helmet>
      <h3 className='fw-light font-monospace text-center'>Olvidó su Contraseña / Validación de Cuenta</h3>
      {isSuccess && (
        <Message variant='success'>{message}</Message>
      )}
      {isError && <Message variant='danger'>{error}</Message>}

      <form onSubmit={handleSubmit(submitHandler)}>

      <div className="alert alert-primary" role="alert">
        Ingrese su correo electrónico o número celular registrado en el sistema y de clic en el botón Enviar. Le llegará información de como realizar el proceso de Cambio de Clave o el reenvío de la validación de cuenta.
      </div>
      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-8'>
          <input
            { ...register("identification",  { required: `* Campo requerido` })}
              type='text'
              name='identification'
              placeholder="Correo electrónico o número telefónico"
              value={identification}
              onChange={(event) => setIdentification(event.target.value)}
              className='form-control'
            />
            {errors && errors['identification'] && (
              <span className='text-danger'>{errors['identification'].message}</span>
            )}
        </div> 
        <div className='col-md-2'></div>
      </div>             
      <br/>      
      <div className='row'>
        <div className='col-md-6' style={{padding:'10px'}}>
          <Link to='/auth/login' className='btn btn-primary form-control' >
            <AiOutlineHome /> &nbsp;    Retornar Página Inicio
          </Link>
        </div>
        <div className='col-md-6' style={{padding:'10px'}}>
          <button
            type='submit'
            className='btn btn-primary form-control '
            disabled={isLoading}>
            {isLoading ? (
              <span className='spinner-border spinner-border-sm' />
            ) : (
              ''
            )} <AiOutlineMail /> &nbsp; Enviar
          </button>
        </div>    

      </div>        
      <div className='row'>&nbsp;</div>
        
      </form>
    </FormContainer>
  )
}

export default ForgotPassword
