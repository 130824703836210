import dynamicAPI from './dynamicAPI'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useLocation  } from 'react-router-dom'

const url = '/api/auth/branches'

const queryKey = '' 

export default function useBranchesHook(props) {
  const { page = 1, id, q = '', limit = 25, isAdmin = '' } = props
  const queryClient = useQueryClient()

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)
  const organization_id = searchParams.get('organization_id')

  const getBranches = organization_id!=undefined ? useQuery(
    queryKey,
    async () =>
      await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}&organization_id=${organization_id}`, {}),
    { retry: 0 }
  ):''

  const getBranchesAll = isAdmin=="Admin" ? useQuery(
    queryKey,
    async () =>
      await dynamicAPI('get', `${url}/getBranchesAll`, {}),
    { retry: 0 }
  ):''

  const postBranchesByOrganizationUnique = useMutation(
    async (obj) => await dynamicAPI('post', `${url}/postBranchesByOrganizationUnique`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const getBranchById = useQuery(
    queryKey,
    async (id) => await dynamicAPI('get', `${url}/${id}`, {}),
    { retry: 0, enabled: !!id }
  )

  const updateBranch = useMutation(
    async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const deleteBranch = useMutation(
    async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  const postBranch = useMutation(
    async (obj) => await dynamicAPI('post', url, obj),
    {
      retry: 0,
      onSuccess: () => queryClient.invalidateQueries([queryKey]),
    }
  )

  return {
    getBranches,
    getBranchesAll,
    updateBranch,
    deleteBranch,
    postBranch,
    postBranchesByOrganizationUnique,
    getBranchById,
  }
}
