import moment from 'moment'
import { FaPenAlt, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { DatatableWrapper, Filter, Pagination, PaginationOptions, EmptyTablePlaceholder, TableBody, TableHeader } from 'react-bs-datatable'
import { Col, Row, Table } from 'react-bootstrap'

const headers = [
  { title: 'Fecha de creación', prop: 'createdAt', alignment: { horizontal: 'center' }, isFilterable: true },
  { title: 'Nombres', prop: 'name', alignment: { horizontal: 'center' }, isFilterable: true },
  { title: 'Apellidos', prop: `lastname`, alignment: { horizontal: 'center' }, isFilterable: true },
  { title: 'Identificación', prop: 'identification', alignment: { horizontal: 'center' }, isFilterable: true },
  { title: 'Número Celular', prop: 'phone', alignment: { horizontal: 'center' }, isFilterable: true },
  { title: 'Email', prop: 'email', alignment: { horizontal: 'center' }, isFilterable: true },
  { title: 'Ingreso Contraseña', prop: 'accountValidationInitialPasswordToken', alignment: { horizontal: 'center' }, isFilterable: true },
  { title: 'Bloqueo General', prop: 'blocked', alignment: { horizontal: 'center' }, isFilterable: true },
  { title: 'Acciones', prop: '', alignment: { horizontal: 'center' } }
]

const ViewUsers = ({
  data,
  editHandler,
}) => {
  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <div className='d-flex align-items-center flex-column mb-2'>
        <h3 className='fw-light text-muted'>
          Listado de Usuarios
        </h3>

      </div>

      {data != undefined ? (
        <DatatableWrapper body={data.data} headers={headers} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 15,
            options: [5, 10, 15, 20]
          }
        }} >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter placeholder={'Ingrese información del usuario a encontrar'} />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions labels={{ beforeSelect: `Filas por página / Usuarios del sistema encontrados ${data.data.length}` }} />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Pagination labels={{ firstPage: 'Inicio', lastPage: 'Final', nextPage: 'Siguiente', prevPage: 'Anterior' }} />
            </Col>
          </Row>
          <hr></hr>
          <Table>
            <TableHeader />
            <TableBody>
              {(rows) =>
                rows.length === 0 ? (
                  <EmptyTablePlaceholder noResultsLabel={'Usuarios no encontrados'} />
                ) : (
                  rows.map((rowData, rowIdx) => (
                    <tr key={rowIdx} className='text-center'>
                      <td>{moment(rowData.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                      <td>{rowData.name}</td>
                      <td>{rowData.lastname}</td>
                      <td>{rowData.identification}</td>
                      <td>
                        {rowData.accountValidationPhoneToken == '' ? (
                          <FaCheckCircle className='text-success' />
                        ) : (
                          <FaTimesCircle className='text-danger' />
                        )}&nbsp;&nbsp;{rowData.phone}
                      </td>
                      <td>
                        {rowData.accountValidationEmailToken == '' ? (
                          <FaCheckCircle className='text-success' />
                        ) : (
                          <FaTimesCircle className='text-danger' />
                        )}&nbsp;&nbsp;{rowData.email}
                      </td>
                      <td>
                        {rowData.accountValidationInitialPasswordToken == undefined ? (
                          <FaCheckCircle className='text-success' />
                        ) : (
                          <FaTimesCircle className='text-danger' />
                        )}
                      </td>
                      <td>
                        {rowData.accountValidationInitialPasswordToken == undefined ? (
                          <FaCheckCircle className='text-success' />
                        ) : (
                          <FaTimesCircle className='text-danger' />
                        )}
                      </td>
                      <td>
                        {rowData.blocked ? (
                          <FaCheckCircle className='text-success' />
                        ) : (
                          <FaTimesCircle className='text-danger' />
                        )}
                      </td>


                      <td>
                        <div className='btn-group'>

                          <button
                            className='btn btn-primary btn-sm rounded-pill'
                            onClick={() => editHandler(rowData)}
                            data-bs-toggle='modal'
                            data-bs-target='#userModal'
                            data-bs-placement="top" title="Clic para Editar Usuario"
                          >
                            <FaPenAlt />
                          </button>






                        </div>
                      </td>
                    </tr>

                  )
                  )
                )
              }
            </TableBody>
          </Table>
        </DatatableWrapper>) : ''}



    </div>
  )
}

export default ViewUsers
