import { Message } from "..";
import React, { useState } from "react";
import useEventOrganizationsHook from '../../api/event-organizations'
import { AiOutlineAudit } from "react-icons/ai"


const FormEvents = ({
  id,
  edit,
  event,
  isSuccess,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  // Estos manejadores controlan el estado de 'isDragging'
  const handleIframeMouseDown = () => setIsDragging(true);
  const handleIframeMouseUp = () => setIsDragging(false);
  const [detail, setDetail] = useState('');

  const handleDetail = event => {
    setDetail(event.target.value);
  }

  const { updateEventOrganization } = useEventOrganizationsHook({ page: 1 })

  const handleClick = async () => {

    if (detail.length == 0) {
      alert('Ingrese detalle del evento');
      return false;
    }

    await mutateAsyncUpdate({
      _id: id,
      state: 'Atendida',
      detail: detail,
    })
    setDetail('')
    document.getElementById("close-event-organization-modal").click()
  }

  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoading } = updateEventOrganization


  return (
    <div
      className="modal fade"
      id="eventModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="eventModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-grande">
        <div className="modal-content modal-background">
          <div className="modal-header">
            <h4 className="modal-title " id="eventModalLabel">
              {edit ? "Generación Reporte del Evento" : "Publicar Evento"}
            </h4>
            <button
              id="close-event-organization-modal"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">

            <div className="row">
              <div className="col-md-4">

                {isSuccess && (
                  <Message variant='success'>
                    El evento se ha actualizado correctamente.
                  </Message>
                )}
                <div className="row" style={{ color: "red" }}>
                  <center>
                    <h6 className=" text-center blink_effect">USUARIO SOLICITANTE</h6>
                  </center>
                  <center>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={event && event.image}
                        alt=""
                        className='rounded-circle'
                        width='150'
                        height='150'
                      />
                    </div></center>
                </div>
                <div className="row">
                  <div className="col-md-12 ">
                    <center><label>{event && event.userName}</label></center>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="alert alert-primary" role="alert">
                      <center><label><b>Datos Personales</b></label></center>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ fontSize: '20px' }}>
                  <div className="col-md-5">
                    <label><b>Número Celular: </b></label>
                  </div>
                  <div className="col-md-7">
                    <div>{event.phone!= undefined ?event.phone.replace("+593", '0'):''}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <b>Correo electrónico: </b>
                  </div>
                  <div className="col-md-7">
                    <div>{event.email}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <b>Sexo: </b>
                  </div>
                  <div className="col-md-7">
                    <div>{event.sex}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <b>Identicación: </b>
                  </div>
                  <div className="col-md-7">
                    <div>{event.identification}</div>
                  </div>
                </div>

                <br></br>
                <div className="row">
                  <div className="col-md-12">
                    <div className="alert alert-primary" role="alert">
                      <center><label><b>Servicio Contratado</b></label></center>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <b>Empresa: </b>
                  </div>
                  <div className="col-md-7">
                    <div>{event.organization}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <b>Sucursal: </b>
                  </div>
                  <div className="col-md-7">
                    <div>{event.branch}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <b>Servicio: </b>
                  </div>
                  <div className="col-md-7">
                    <div>{event.service}</div>
                  </div>
                </div>

              </div>
              <div className="col-md-4">
                {/* Primer Mapa */}
                <div
                  className="col"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "250px",
                    marginBottom: "20px", // Agrega un margen para separar los mapas
                  }}
                >
                  <iframe
                    width="100%"
                    height="250" // Altura ajustada previamente
                    loading="lazy"
                    allow="accelerometer; gyroscope;"
                    referrerPolicy="no-referrer"
                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${event.latitude},${event.longitude}`}
                    style={{
                      border: 0,
                      maxWidth: "1500px", // Ajusta el ancho máximo aquí
                      margin: "auto",
                    }}
                  ></iframe>
                </div>

                {/* Segundo Mapa */}
                <div
                  className="col"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative", // Esto asegura que el div hijo absoluto se posicione relativo a este contenedor
                    height: "250px", // Asegura que este contenedor tenga una altura definida
                  }}
                >
                  <div
                    onMouseDown={handleIframeMouseDown}
                    onMouseUp={handleIframeMouseUp}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "250px",
                      width: "100%", // Hace que el div cubra el ancho del contenedor padre
                      maxWidth: "1500px", // Limita el ancho máximo del div
                      cursor: isDragging ? "grabbing" : "grab",
                      pointerEvents: isDragging ? "none" : "auto",
                      margin: "auto", // Centra el div en caso de que el contenedor padre sea más ancho
                    }}
                  >
                    <iframe
                      width="100%"
                      height="250px"
                      loading="lazy"
                      allow="accelerometer; gyroscope;"
                      referrerPolicy="no-referrer"
                      src={`https://www.google.com/maps/embed/v1/streetview?key=${process.env.REACT_APP_GOOGLE_API_KEY}&location=${event.latitude},${event.longitude}&heading=210&pitch=10&fov=100`}
                      style={{
                        border: 0,
                        pointerEvents: "all",
                        maxWidth: "1500px",
                      }}
                    ></iframe>
                  </div>
                </div>
              </div>

              <div className="col-md-4">

                <div className="row">
                  <div className="col-md-12">
                    <div className="alert alert-primary" role="alert">
                      <center><label><b>Detalles del Evento</b></label></center>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 ">
                    <label><b>Ubicación del evento: </b>{event && event.locationName}</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 ">
                    <label><b>Fecha y hora del evento: </b>{event.createdAt}</label>
                  </div>
                </div>
                <br></br>
                {event.isMemberBranch == false ? (<div>
                  <div className="row">
                    <div className="col-md-12">
                      <textarea id="detail" name="detail" rows={8} style={({ width: '100%' })} onChange={handleDetail} value={detail} />
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-12">
                      <center>
                        <button onClick={handleClick} className="btn btn-danger">


                          {isLoading ? (
                            <span className='spinner-border spinner-border-sm' />
                          ) : ''}

                          <AiOutlineAudit />

                          &nbsp;Atender Evento</button>
                      </center>
                    </div>
                  </div></div>) : ''}
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default FormEvents;
