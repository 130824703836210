import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import useOrganizationsHook from '../../../api/organizations'
import {
  Message,
} from '../../../components'
import FormOrganizations from '../../../components/organizations/FormOrganizations'
import ViewOrganizations from '../../../components/organizations/ViewOrganizations'
import useAuth from '../../../hooks/useAuth'

const Organizations = () => {
  const [page, setPage] = useState(1)
  const [id, setId] = useState('')
  var [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')
  const [users, setUsers] = useState([])
  var [image, setImage] = useState('')
  var [isAdmin, setIsAdmin] = useState(false)
  var [userId, setUserId] = useState('')
  var [organizationId, setOrganizationId] = useState('')
  var [organizations, setOrganizations] = useState([])
  var [organization, setOrganization] = useState([])
  const { auth } = useAuth()

  var usersList = []

  const { getOrganizations } = useOrganizationsHook({ page, q })

  const {
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      auth: true,
    },
  })

  var { data, isError, error, refetch } = getOrganizations

  const formCleanHandler = () => {
    setEdit(false)
    reset()
  }

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    if (!q) refetch()
  }, [q])


  useEffect(() => {

    const userRoles = localStorage.getItem('userRole') ? JSON.parse(localStorage.getItem('userRole')): null
 
    setUserId(auth?.userInfo?._id)

    if(userRoles.isSuperAdmin) {
      setIsAdmin(true)

      if(data != undefined) {
        setOrganizations(data.data)
      }
    } else {
      setIsAdmin(false)
      
      // Obtenemos el rol del usuario actual
      for (let i = 0; i < userRoles.listRoleOrganizationBranch.length; i++) {  
        if(userRoles.listRoleOrganizationBranch[i].role_type == 'ADMIN_ORGANIZATION') {
          setOrganizationId(userRoles.listRoleOrganizationBranch[i].organization_id)
        }
      }
      if(data != undefined) {
        setOrganizations(data.data.filter(organizationObject =>organizationObject._id === organizationId))
      }
    }

  }, [organizations, data])

  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }


  const editHandler = (organization) => {
    setId(organization._id)
    setEdit(true)
    // Buscamos la imagen de la organizacion

    setImage(organization.image)
    setOrganization(organization)

    for (let i = 0; i < organization.users.length; i++) {
      usersList.push({
        _id: organization.users[i].user._id,
        typeIdentification: organization.users[i].user.typeIdentification,
        identification: organization.users[i].user.identification,
        birthday: organization.users[i].user.birthday,
        sex: organization.users[i].user.sex,
        name: organization.users[i].user.name,
        lastname: organization.users[i].user.lastname,
        email: organization.users[i].user.email,
        phone: organization.users[i].user.phone,
        accountValidationEmailToken: organization.users[i].user.accountValidationEmailToken,
        accountValidationPhoneToken: organization.users[i].user.accountValidationPhoneToken,
        accountValidationInitialPasswordToken: organization.users[i].user.accountValidationInitialPasswordToken,
        accountValidationInitialPasswordDate: organization.users[i].user.accountValidationInitialPasswordDate,
        active: organization.users[i].active,  
        role: organization.users[i].typeUser
      })
    }
    setUsers(usersList)
  }


  const showHandler = (organization) => {
    setId(organization._id)
    setEdit(false)
    setValue('name', organization.name)
    setValue('typeIdentification', organization.typeIdentification)
    setValue('identification', organization.identification)
    setValue('description', organization.description)
    setValue('isPublic', organization.isPublic)
    setValue('active', organization.active)
    setImage(organization.image)
    setOrganization(organization)

    for (let i = 0; i < organization.users.length; i++) {
      usersList.push({
        _id: organization.users[i].user._id,
        typeIdentification: organization.users[i].user.typeIdentification,
        identification: organization.users[i].user.identification,
        birthday: organization.users[i].user.birthday,
        sex: organization.users[i].user.sex,
        name: organization.users[i].user.name,
        lastname: organization.users[i].user.lastname,
        email: organization.users[i].user.email,
        phone: organization.users[i].user.phone,
        accountValidationEmailToken: organization.users[i].user.accountValidationEmailToken,
        accountValidationPhoneToken: organization.users[i].user.accountValidationPhoneToken,
        accountValidationInitialPasswordToken: organization.users[i].user.accountValidationInitialPasswordToken,
        accountValidationInitialPasswordDate: organization.users[i].user.accountValidationInitialPasswordDate,
        active: organization.users[i].active,  
        role: organization.users[i].typeUser
      })
    }
    setUsers(usersList)
  }

  return (
    <>
      <Helmet>
        <title>Organizaciones</title>
        <meta property='og:title' content='Organizaciones' key='title' />
      </Helmet>

      
      {isError && <Message variant='danger'>{error}</Message>}

      <FormOrganizations
        id={id}
        edit={edit}
        users={users}
        image={image}
        formCleanHandler={formCleanHandler}
        isError={isError}
        errors={errors}
        error={error}
        isAdmin={isAdmin}
        organization={organization}
        userId={userId}
      />

    
        <ViewOrganizations
          data={organizations}
          editHandler={editHandler}
          showHandler={showHandler}
          setQ={setQ}
          q={q}
          searchHandler={searchHandler}
          isAdmin={isAdmin}
        />
      
    </>
  )
}

export default Organizations
