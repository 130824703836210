import React, { useState } from "react"
import { AiOutlineFileSearch, AiOutlineClear } from "react-icons/ai"
import moment from 'moment'
import useReportHook from '../../api/report'
import useBranchesHook from '../../api/branches'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { saveAs } from 'file-saver'
import { DatatableWrapper, Filter, Pagination, PaginationOptions, EmptyTablePlaceholder, TableBody, TableHeader } from 'react-bs-datatable'
import { Col, Row, Table } from 'react-bootstrap'
import { Message } from '..'

const ViewEvents = ({
  organizationBranches,
  organizations,
  networkSubNetworks,
}) => {

  const [startDate, setStartDate] = useState(moment().subtract(1, 'month').format('yyyy-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'))
  const [state, setState] = useState('')
  const { postReport } = useReportHook()
  var [organization, setOrganization] = useState('')
  var [formatReport, setFormatReport] = useState('')
  var [typeReport, setTypeReport] = useState('')
  var [identification, setIdentification] = useState('')
  var [branch, setBranch] = useState('')
  var [subNetwork, setSubNetwork] = useState('')
  var { postBranchesByOrganizationUnique } = useBranchesHook({})
  var [branches, setBranches] = useState([])
  var [subNetworks, setSubNetworks] = useState([])
  var [events, setEvents] = useState([])
  var { mutateAsync: mutateAsyncbranchesUniqueByOrganization } = postBranchesByOrganizationUnique

  const { isLoading: isLoadingReport, error: errorValidations, mutateAsync: mutateAsyncReport } = postReport

  var isAdmin = false
  var seeAllOrganization = false
  var seeAllBranch = false
  var memberBranch = false
  var [seeAllMember, setSeeAllMember] = useState(false)

  const headers = [
    { title: 'Nombre Completo', prop: 'name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Teléfono', prop: 'createdAt', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Evento', prop: `user`, alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Estado', prop: 'state', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Coordenadas', prop: 'branch.name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Sucursal', prop: 'service.name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Empresa', prop: 'organization.name', alignment: { horizontal: 'center' }, isFilterable: true },
    { title: 'Fecha de Evento', prop: '', alignment: { horizontal: 'center' } }
  ];



  const handleChangeOrganizations = async (organizationId) => {

    var branchlist = []

    if (organizationId != "") {

      setOrganization(organizationId)

      getRoleBranch(organizationId)

      // Si no es member_branch
      if (memberBranch == false) {

        if (isAdmin == true || seeAllOrganization == true) {

          var existEventOrganizacionBranchList = []
          existEventOrganizacionBranchList = await mutateAsyncbranchesUniqueByOrganization({ organization_id: organizationId })

          for (let i = 0; i < existEventOrganizacionBranchList.data.length; i++) {
            branchlist.push({ _id: existEventOrganizacionBranchList.data[i]._id, name: existEventOrganizacionBranchList.data[i].name })
          }

        }

        if (seeAllBranch == true) {
          for (let i = 0; i < organizationBranches.length; i++) {
            if (organizationBranches[i].organization_id == organizationId) {
              branchlist.push({ _id: organizationBranches[i]._id, name: organizationBranches[i].name })
            }
          }
        }
      } else {

        // Ponemos datos de las sucursales por organizacion
        for (let i = 0; i < organizationBranches.length; i++) {
          if (organizationBranches[i].organization_id == organizationId) {
            branchlist.push({ _id: organizationBranches[i]._id, name: organizationBranches[i].name })
          }
        }
      }

      setBranches(branchlist)
    } else {
      setOrganization('')
      setEvents([])
      setBranches('')
    }
  }


  // Metodo para agregar las sucursales segun rol
  const handleChangeBranches = async (branchId) => {

    setBranch(branchId)

    if (branchId == "") {
      setEvents([])
    } else {
      setEvents([])

      // Actualizamos el rol por organizacion
      getRoleBranch(organization)

      if (memberBranch == false) {
        // 1. Consulta de todos los eventos de la organizacion y de la sucursal

      } else {
        var listSubNetworks = []
        for (let i = 0; i < networkSubNetworks.length; i++) {
          listSubNetworks.push({ _id: networkSubNetworks[i]._id, name: networkSubNetworks[i].name })
        }
        setSubNetworks(listSubNetworks)
        setSeeAllMember(true)
      }
    }
  }

  // Método para consultar el contenido del reporte
  const generateReport = async () => {

    const reportList = await mutateAsyncReport({
      type: 'report-events',
      startDate: startDate,
      endDate: endDate,
      state: state,
      organization: organization,
      identification: identification,
      branch: branch,
      formatReport: formatReport,
      typeReport: typeReport,
      isMemberBranch: memberBranch,
      subNetworkId: subNetwork,
    })

    if (formatReport == 'PDF') {

      const itemDetailsRows = reportList.data?.map((item) => [
        item.Identification.toString(),
        item.Nombre_Completo.toString(),
        item.Telefono?.toString(),
        item.Evento?.toString(),
        item.Estado?.toString(),
        item.Coordenadas?.toString(),
        item.Sucursal?.toString(),
        item.Empresa?.toString(),
        moment(item.Fecha_Evento?.toString()).format("YYYY-MM-DD HH:mm:ss"),
      ]);

      const doc = new jsPDF('landscape')
      autoTable(doc, {
        head: [['Identificación','Nombre Completo', 'Teléfono', 'Evento', 'Estado', 'Coordenadas', 'Sucursal', 'Empresa', 'Fecha de Evento']],
        body: itemDetailsRows,
      })
      doc.save('reporte.pdf')
    }

    if (formatReport == 'Excel') {

      const jsonFromTsObjectArray = reportList.data.map(value => ({
        'IDENTIFICACION': value.Identification,
        'NOMBRE COMPLETO': value.Nombre_Completo,
        'TELEFONO': value.Telefono,
        'EVENTO': value.Evento,
        'SEXO': value.sex,
        'ESTADO': value.Estado,
        'COORDENADAS': value.Coordenadas,
        'SUCURSAL': value.Sucursal,
        'EMPRESA': value.Empresa,
        'FECHA EVENTO': moment(value.Fecha_Evento).format("YYYY-MM-DD HH:mm:ss"),
      }))

      const worksheet = XLSX.utils.json_to_sheet(jsonFromTsObjectArray)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "Historial de Eventos")
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
      saveAs(blob, "data.xlsx")

    }

    setEvents(reportList.data)
  }

  // Metodo para agregar subredes para que seleccione el usuario solo member_branch
  const handleChangeSubNetworks = async (subNetworkId) => {

    if (subNetworkId != "") {
      setSubNetwork(subNetworkId)
    } else {
      setEvents([])
      setSubNetwork('')
    }
  }

  // Método para limpiar 
  const cleanFormSearch = async () => {

    setOrganization('')
    setBranch('')
    setIdentification('')
    setFormatReport('')
    setTypeReport('')
    setState('')
    setEvents([])

  }

  // Metodo para obtener el rol por medio de la organizacion
  function getRoleBranch(organizationId) {

    for (let i = 0; i < organizationBranches.length; i++) {
      if (organizationBranches[i].organization_id == organizationId) {

        if (organizationBranches[i].isAdmin) {
          isAdmin = true
        }

        if (organizationBranches[i].role_type == 'ADMIN_ORGANIZATION' || organizationBranches[i].role_type == 'MONITOR_ORGANIZATION') {
          seeAllOrganization = true
        }

        if (organizationBranches[i].role_type == 'ADMIN_BRANCH' || organizationBranches[i].role_type == 'MONITOR_BRANCH') {
          seeAllBranch = true
        }

        if (organizationBranches[i].role_type == 'MEMBER_BRANCH') {
          memberBranch = true
        }
      }
    }
  }

  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      {errorValidations && <Message variant='danger'>{errorValidations}</Message>}
      <div className='d-flex  flex-column text-center'>
        <div className='row'>
          <div className='col-md-12'>
            <h3 className='fw-light text-muted'>
              Organización - Reporte Dinámico
            </h3>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3'>
          <label htmlFor="organization"><b>Organización:</b></label>
          <select onChange={(event) => handleChangeOrganizations(event.target.value)}
            id='organization' name='organization' className='form-control' value={organization}>
            <option value="" >Seleccione una opción</option>
            {organizations && organizations.map((organization) => (
              <option key={organization._id} value={organization._id} >{organization.name}</option>
            ))}
          </select>
        </div>
        <div className='col-md-3'>
          <label htmlFor="branch"><b>Sucursal:</b></label>
          <select onChange={(event) => handleChangeBranches(event.target.value)} value={branch}
            id='branch' name='branch' className='form-control' >
            <option value="" >Seleccione una opción</option>
            {branches && branches.map((branch) => (
              <option key={branch._id} value={branch._id} >{branch.name}</option>
            ))}
          </select>
        </div>
        <div className='col-md-3'>
          <label htmlFor="typeReport"><b>Formato de Reporte</b></label>
          <select onChange={(event) => setFormatReport(event.target.value)}
            name='formatReport' id="formatReport" className='form-control' value={formatReport}>
            <option value="" >Seleccione una opción</option>
            <option value="PDF" >PDF</option>
            <option value="Excel" >Excel</option>
          </select>
        </div>
        <div className='col-md-3'>
          <label htmlFor="identification"><b>Identificación:</b></label>
          <input
            type='text'
            name='identification'
            value={identification}
            onChange={(event) => setIdentification(event.target.value)}
            className='form-control' />
        </div>
      </div>
      {seeAllMember == true ? (
        <div><div className='row'>
          <div><div className='col-md-6'>
            <label htmlFor="subNetwork"><b>Redes:</b></label>
            <select onChange={(event) => handleChangeSubNetworks(event.target.value)}
              id='subNetwork' name='subNetwork' className='form-control' value={subNetwork}>
              <option value="" >Seleccione una opción</option>
              {subNetworks && subNetworks.map((subNetwork) => (
                <option key={subNetwork._id} value={subNetwork._id} >{subNetwork.name}</option>
              ))}
            </select>
          </div>
            <div className='col-md-6'>

            </div>
          </div></div><br></br></div>) : ''}

      <div className='row'>
        <div className='col-md-3'>
          <label htmlFor="startDate"><b>Fecha de Inicio:</b></label>
          <input
            type='date'
            name='startDate'
            value={startDate}
            onChange={(event) => setStartDate(event.target.value)}
            className='form-control'
          />
        </div>
        <div className='col-md-3'>
          <label htmlFor="endDate"><b>Fecha Fin:</b></label>
          <input
            type='date'
            name='endDate'
            value={endDate}
            onChange={(event) => setEndDate(event.target.value)}
            className='form-control'
          />
        </div>
        <div className='col-md-3'>
          <label htmlFor="state"><b>Estado:</b></label>
          <select onChange={(event) => setState(event.target.value)}
            name='state' className='form-control' value={state}>
            <option value="" >Seleccione una opción</option>
            <option value="TODOS" >TODOS</option>
            <option value="En proceso de atencion" >En proceso de atencion</option>
            <option value="Atendida" >Atendida</option>
            <option value="Archivada" >Archivada</option>
          </select>
        </div>
        <div className='col-md-3'>
          <label htmlFor="state"><b>Tipo de Reporte:</b></label>
          <select onChange={(event) => setTypeReport(event.target.value)}
            id='typeReport' name='typeReport' className='form-control' value={typeReport}>
            <option value="" >Seleccione una opción</option>
            <option value="Eventos" >Eventos de la Organización</option>
          </select>
        </div>
      </div>
      <br></br>
      <div className='row'>
        <div className='col-md-4'>
        </div>
        <div className='col-md-4 text-center'>

          <button type="button" onClick={generateReport} className="btn btn-primary">
            {isLoadingReport ?
              (<span className='spinner-border spinner-border-sm' />) :
              ('')}
            &nbsp; <AiOutlineFileSearch /> Generar</button>&nbsp;&nbsp;


          <button type="button" onClick={cleanFormSearch} className="btn btn-primary">

            &nbsp; <AiOutlineClear /> Limpiar</button>
        </div>
        <div className='col-md-4'>
        </div>
      </div>
      <br></br>

      {events.length > 0 ? (

        <DatatableWrapper body={events} headers={headers} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 15,
            options: [5, 10, 15, 20]
          }
        }} >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter placeholder={'Ingrese información a encontrar'} />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
            >
              <PaginationOptions labels={{ beforeSelect: `Filas por página ` }} />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Pagination labels={{ firstPage: 'Inicio', lastPage: 'Final', nextPage: 'Siguiente', prevPage: 'Anterior' }} />
            </Col>
          </Row>
          <hr></hr>
          <Table>
            <TableHeader />
            <TableBody>
              {(rows) =>
                rows.length === 0 ? (
                  <EmptyTablePlaceholder noResultsLabel={'Regitros no encontrados'} />
                ) : (
                  rows.map((rowData, rowIdx) => (
                    <tr key={rowIdx} className='text-center'>
                      <td>{rowData.Nombre_Completo}</td>
                      <td>{rowData.Telefono}</td>
                      <td>{rowData.Evento}</td>
                      <td>{rowData.Estado}</td>
                      <td>{rowData.Coordenadas}</td>
                      <td>{rowData.Sucursal}</td>
                      <td>{rowData.Empresa}</td>
                      <td>{moment(rowData.Fecha_Evento).format("YYYY-MM-DD HH:mm:ss")}</td>
                    </tr>
                  )
                  )
                )
              }
            </TableBody>
          </Table>
        </DatatableWrapper>) : ''}
    </div>
  )
}

export default ViewEvents
