
import { FormContainer, Message } from '../../components'
import accountValidationUserProfileHook from '../../api/account-validation-user-profile'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'


const AccountValidationUserProfile = () => {

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  var messageValidation = ''
  const [remainingTime, setRemainingTime] = useState(10)
  const [typeCredential, setTypeCredential] = useState('')
  const [user, setUser] = useState('')
  const [value, setValue] = useState('')


  var { getAccountValidationUserProfile } = accountValidationUserProfileHook({
    token: searchParams.get('token'),
    id: searchParams.get('id'),
    origin: searchParams.get('origin'),
  })



  var { data } = getAccountValidationUserProfile

  let interval

  useEffect(() => {

    try {

      setTypeCredential(searchParams.get('typeCredential'))
      setUser(searchParams.get('user'))
      setValue(searchParams.get('value'))

      console.log('typeCredential')
      console.log(typeCredential)

      interval = setInterval(() => {
        setRemainingTime(
          (prevRemainingTime) =>
            prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 // Reduce por 1 segundo
        )
      }, 1000)

      if (remainingTime == 0) {

        if (localStorage.getItem('userInfo') == undefined) {
          if (typeCredential != 'MIID') {
            navigate('/auth/login')
          }
        } else
          navigate('/account/profile')

      }

      return () => {
        clearInterval(interval)
      }

    } catch (error) {
      console.log(error)
    }


  }, [data, remainingTime])

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  return (
    <FormContainer>
      <Helmet>
        <title>Validación de Correo Electrónico o Teléfono</title>
        <meta property='og:title' content='Validación de Correo Electrónico o Teléfono' key='title' />
      </Helmet>
      <center><h3 className=''>Validación de Cuenta</h3></center>
      <hr></hr>


      {messageValidation != '' ? <Message variant='danger'>{messageValidation}</Message> : ''}

      <form >

        <div className="card">
          <div className="card-header text-center">
            <b>Se realizó el proceso de validación de su cuenta</b>
          </div>
          <div className="card-body">


            {typeCredential == 'MIID' ?
              (<div className='row'>

                <div className='col-md-12 text-center'>
                  Cierre esta pantalla y acceda al aplicativo Alerta Global con el siguiente usuario y contraseña:
                </div>
                <div className='col-md-12'>
                &nbsp;
                </div>
                
                <div className='row' style={{fontSize:'20px'}}>
                  <div className='col-md-6 text-center'>
                    Usuario:
                  </div>
                  <div className='col-md-6 text-left'>
                    <b>{user}</b>
                  </div>
                </div>
                <div className='row' style={{fontSize:'20px'}}>
                  <div className='col-md-6 text-center'>
                    Contraseña:
                  </div>
                  <div className='col-md-6 text-left'>
                    <b>{value}</b>
                  </div>
                </div>

              </div>) :
              (<div>
                <center><span className='spinner-border spinner-border-sm' /></center>
                Se redireccionará en <b>{millisToMinutesAndSeconds(remainingTime * 1000)}</b> segundos a la  {localStorage.getItem('userInfo') == undefined ? ' página de inicio para que acceda al sistema' : ' página del perfil de su usuario'}.
              </div>)
            }

          </div>
        </div>



      </form>
    </FormContainer>
  )
}

export default AccountValidationUserProfile
